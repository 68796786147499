import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IdName } from '@bs/models/common/id-name';
import { CatalogService } from '@bs/services/services/core/catalog.service';
import { Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

/**
 *  \    / /\  |_) |\ |  |  |\ | /__
 *   \/\/ /--\ | \ | \| _|_ | \| \_|
 *
 *  DUE TO THE IMPACT THIS FILE HAS ON THE PLATFORM DO NOT CHANGE ANYTHING AND REFER TO YOUR TEAM LEADER
 * */
@Component({
  template: ``,
  styles: [`
    :host {
      display: block;
      width: 100%;
    }
  `],
  standalone: true
})
export class BaseCausalGroups implements ControlValueAccessor, OnDestroy {
  /**
   * responsible for disabling the field
   */
  isDisabled: boolean;
  /**
   * local reference for causalId
   */
  causalId: IdName<number> = null;
  /**
   * local reference for causalGroupId
   */
  causalGroupId: IdName<number> = null;
  /**
   * when from the options variable input decorator we receive forced values, this boolean keeps track of it
   */
  causalGroupIdForced: boolean;

  /**
   * outputs the blur effect flow from the child to the parent
   */
  @Output() blur = new EventEmitter<any>(null);

  /**
   * option values that we receive from parent
   */
  @Input()
  options: any;
  /**
   * local reference of IdName<any, number>[]
   */
  causalGroupsValues: IdName<any, number>[];
  /**
   * variable walletTypeId, default set to 1
   */
  walletTypeId = 1;
  /**
   * local reference of IdName<any, number>[]
   */
  causalIdsValues: IdName<any, number>[];
  /**
   * local reference to Subscription
   */
  subs = new Subscription();

  /**
   * The constructor where we fetch the causalGroups from catalogService, and initialize the values of causalId and causalIdsValues
   * @param route
   * @param catalogService
   */
  constructor(private route: ActivatedRoute, private catalogService: CatalogService) {

    this.subs.add(route.queryParamMap.pipe(
      switchMap(query => {
        if (query.has('walletTypeId')) {
          this.walletTypeId = +query.get('walletTypeId');
        }
        return this.catalogService.causalGroups(this.walletTypeId, [1]).pipe(map(causal => ({ causal, query })));
      })
    ).subscribe({
      next: result => {
        if (result.query.has('causalGroupId')) {
          this.catalogService.causals(this.walletTypeId, +result.query.get('causalGroupId'), []).subscribe({
            next: res => {
              this.causalIdsValues = res;
              this.causalId = result.query.get('causalId') ? this.causalIdsValues?.find(r => r.id === +result.query.get('causalId')) : null;
            }
          });
        } else {
          // [this.causalId, this.causalIds] = [undefined, undefined];
        }
        this.causalGroupsValues = result.causal;

        if (this.options) {
          if (this.options?.force) {
            const { causalGroupId, causalId } = this.options.force;

            this.causalGroupId = this.causalGroupsValues.find(v => v.id === causalGroupId);
            this.causalId = causalId;
            this.causalGroupIdForced = true;

            this.causalGroupChange(causalGroupId);
          }
        }
      }
    }));
  }

  /**
   * A lifecycle hook that is called when a directive, pipe, or service is destroyed
   */
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * when some field value has been changed in the view, we register the changes
   * @param causalGroupId
   * @param causalId
   */
  update(causalGroupId: number, causalId: number) {
    this.onTouched();
    this.propagateChange({ causalGroupId, causalId });
  }

  /**
   * emits the blur event to the parent element, and it invokes registerOnTouched method
   * @param event
   */
  onBlur(event) {
    this.onTouched();
    this.blur.emit(event);
  }

  /**
   * updates causalGroupId and causalId when programmatic changes from model to view are requested
   * @param model
   */
  writeValue(model: any): void {
    if (model) {

      if (this.options?.force?.causalGroupId) {
        this.causalGroupId = this.causalGroupsValues.find(v => v.id === this.options.force.causalGroupId);
      } else {
        this.causalGroupId = this.causalGroupsValues.find(v => v.id === model.causalGroupId);
      }

      this.causalId = null;
    } else {
      //handle reset
      this.causalGroupId = null;
      this.causalId = null;
      if (this.causalGroupsValues?.length) {
        this.causalIdsValues.length = 0;
      }
    }
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * when we change causalGroup value in the view, we register change of the field, and we fetch the new causals and initialize to the causalIdsValues
   * @param id
   */
  causalGroupChange(id: number) {
    this.update(id, null);
    if (id) {
      this.catalogService.causals(this.walletTypeId, id, []).subscribe({
        next: res => {
          this.causalIdsValues = res;
          this.causalId = null;
        },

        error: err => console.error(err)
      });
    } else {
      this.causalIdsValues = null;
      this.causalId = null;
    }
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   * @private
   */
  private onTouched() {
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  private propagateChange(_model: any) {
  }
}
