<div class="mb-4">
  <button (click)="logout()" aria-label="Close" class="btn close" tabindex="-1" type="button">&times;</button>
</div>

<div class="modal-body d-flex flex-column align-items-center">
  <h4 class="modal-title">{{ 'realityCheckTitle' | translate }}</h4>
  <h5 class="modal-title mb-2">{{ 'realityCheckSubtitle' | translate }}</h5>

  <div class="playtime d-flex align-items-center mb-2 p-1 border-bottom text-white w-100">
    <svg icon="clock" lib="solid"></svg>
    <p class="mb-0 ml-50">{{ 'playTime' | translate }}</p>
    <span class="ms-auto">{{ playTime }}</span>
  </div>

  <!--<data-table *ngIf="data?.realityChecks.length" [columns]="columns" [rows]="data.realityChecks" class="mb-2 w-100"></data-table>-->

  <div class="custom-control custom-checkbox d-flex align-items-center mb-2">
    <input (ngModelChange)="checked = $event" class="custom-control-input" id="check" ngModel role="button"
           type="checkbox">
    <label class="custom-control-label mb-0 ms-25" for="check" role="button">{{ 'realityCheckExtend' | translate }}</label>
  </div>

  @if (checked) {
    <button mat-button (click)="close()" class="btn btn-accent align-self-center">{{ 'close' | translate }}</button>
  } @else {
    <button (click)="logout()" class="btn btn-accent align-self-center">{{ 'logout' | translate }}</button>
  }
</div>
