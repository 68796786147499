import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatAnchor, MatButton } from '@angular/material/button';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { SvgIconDirective } from '@bs/common/directives/svg-icon.directive';
import { MoneyFormatPipe } from '@bs/common/pipes/money-format.pipe';
import { MaggieMenu } from '@bs/models/cms/enumMenu';
import { IMenuItem, IMenuStatus } from '@bs/models/cms/menu';
import { AppSettings } from '@bs/models/common/app-settings';
import { EnvironmentConfig } from '@bs/models/common/environment-config';
import { IMe } from '@bs/models/me/me';
import { MenuResolver } from '@bs/services/resolvers/core/menu.resolver';
import { AppSettingsService } from '@bs/services/services/core/app-settings.service';
import { AuthService } from '@bs/services/services/core/auth.service';
import { CmsService } from '@bs/services/services/core/cms.service';
import { IdentityWalletService } from '@bs/services/services/identity/identity.wallet.service';
import { WindowService } from '@bs/universal/window.service';
import { DropdownLanguageComponent } from '@homer/common/components/dropdown-language/dropdown-language.component';
import { MenuComponent } from '@homer/common/components/menu/menu.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { first, Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  standalone: true,
  imports: [MatButton, NgClass, MatMenuTrigger, MatMenu, MatAnchor, RouterLink, MoneyFormatPipe, NgTemplateOutlet, TranslateModule, DropdownLanguageComponent, MenuComponent, SvgIconDirective]
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input()
  isMobile = false;

  me: IMe;
  settings: AppSettings;
  showBalance = true;
  homePage = this.config.homePage;
  subs = new Subscription();
  menuStatus: IMenuStatus;
  userMenu: IMenuItem;
  currentLang: string;
  totalBalance: number;
  totalBonuses: number = 0;
  //hasChat = environment.liveChats?.liveChatInc;
  depositRoute: string[];

  constructor(authService: AuthService, appSettingsService: AppSettingsService,
              private menuService: MenuResolver,
              private cmsService: CmsService,
              private config: EnvironmentConfig, private identityWalletService: IdentityWalletService,
              windowService: WindowService,
              translateService: TranslateService) {

    windowService.device$.pipe(first()).subscribe(({ isDesktop }) => {
      if (isDesktop) {
        this.menuService.status = { main: false, section: true, side: false };
      }
    });

    this.subs.add(menuService.menuStatus$.subscribe({
      next: status => this.menuStatus = status
    }));

    this.subs.add(appSettingsService.appSettings$.subscribe({
      next: ({ settings }) => this.settings = settings
    }));

    this.currentLang = translateService.currentLang;

    if (!config.piqMerchantId) {
      this.depositRoute = [this.currentLang, 'me', 'balances', 'deposit'];
    } else {
      this.depositRoute = [this.currentLang, 'me', 'cashier'];
    }

    this.subs.add(authService.accountLogged$.subscribe(
      {
        next: me => {
          if (me) {
            this.me = me;
            this.calculateTotalBalance();
            this.calculateTotalBonuses();
            this.loadUserMenu();
            this.showBalance = 'showBalance' in config.features && config.features['showBalance'];
          } else {
            this.me = null;
          }
        }
      }));
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  reloadBalance(): void {
    void this.identityWalletService.wallets();
  }

  openMenu(status: 'side' | 'section' | 'main') {
    Object.keys(this.menuStatus).forEach(k => {
      if (k === status) {
        this.menuStatus[k] = !this.menuStatus[k];
      } else {
        this.menuStatus[k] = false;
      }
    });
    this.menuService.menuStatus$.next(this.menuStatus);
  }

  private calculateTotalBalance() {
    this.totalBalance = this.me.wallets[0].balance.available;
    this.me.wallets[0].balance.bonuses?.forEach(x => this.totalBalance = x.available + this.totalBalance);
  }

  private calculateTotalBonuses() {
    this.totalBonuses = 0;
    this.me.wallets[0].balance.bonuses?.forEach(x => {
      this.totalBonuses += x.available;
    });
  }

  private loadUserMenu() {
    this.cmsService.getMenu(MaggieMenu.MeNavigationLogged).subscribe({
      next: menu => {
        this.userMenu = menu;
      },

      error: error => console.error(error)
    });
  }
}
