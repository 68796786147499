<ng-template #staticTpl let-birthPlace>
  <div class="col-12 col-md-6">
    <mat-form-field>
      <mat-label>{{ 'country' | translate }}</mat-label>
      <input [value]="birthPlace.country.name" disabled matInput>
    </mat-form-field>
  </div>

  @if (birthPlace.region?.name) {
    <div class="col-12 col-md-6">
      <mat-form-field>
        <mat-label>{{ 'region' | translate }}</mat-label>
        <input [value]="birthPlace.region.name" disabled matInput>
      </mat-form-field>
    </div>
  }

  @if (birthPlace.province?.name || birthPlace.province) {
    <div class="col-12 col-md-6">
      <mat-form-field>
        <mat-label>{{ 'province' | translate }}</mat-label>
        <input [value]="birthPlace.province?.name ? birthPlace.province.name : birthPlace.province" disabled matInput>
      </mat-form-field>
    </div>
  }

  @if (birthPlace.city?.name || birthPlace.city) {
    <div class="col-12 col-md-6">
      <mat-form-field>
        <mat-label>{{ 'city' | translate }}</mat-label>
        <input [value]="birthPlace.city?.name ? birthPlace.city.name : birthPlace.city" disabled matInput>
      </mat-form-field>
    </div>
  }
</ng-template>

<ng-template #fillTpl let-birthPlace>
  <ng-container [formGroup]="birthPlace">
    <div class="col-md-6 my-3">
      <mat-form-field>
        <mat-label>{{ 'country' | translate }}</mat-label>
        <mat-select #countrySelect auto-open (ngModelChange)="setRegions($event)" formControlName="country" panelWidth=""
                    class="dropdown-with-search" position-top-mobile (openedChange)="focusInput($event, countryInput)">
          <mat-form-field appearance="fill" class="dropdown-search-filter">
            <input type="text" matInput [(ngModel)]="searchFilter.country" (keyup.arrowDown)="focusOptions(countrySelect)"
                   #countryInput autocomplete="off" [ngModelOptions]="{standalone:true}">
            @if (searchFilter.country) {
              <button matSuffix mat-icon-button (click)="searchFilter.country=''"></button>
            }
          </mat-form-field>
          @for (country of (countries | filter: searchFn: searchFilter.country); track country) {
            <mat-option
              [value]="country">{{ country.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    @if (regions) {
      <div class="col-md-6 col-12 my-3">
        <mat-form-field>
          <mat-label>{{ 'region' | translate }}</mat-label>
          @switch (!!regions.length) {
            @default {
              <input formControlName="region" matInput>
            }
            @case (true) {
              <mat-select #regionSelect auto-open (ngModelChange)="setProvinces($event)" panelWidth=""
                          formControlName="region" position-top-mobile (openedChange)="focusInput($event, regionInput)">
                <mat-form-field appearance="fill" class="dropdown-search-filter">
                  <input type="text" matInput [(ngModel)]="searchFilter.region" (keyup.arrowDown)="focusOptions(regionSelect)"
                         #regionInput autocomplete="off" [ngModelOptions]="{standalone:true}">
                  @if (searchFilter.region) {
                    <button matSuffix mat-icon-button (click)="searchFilter.region=''"></button>
                  }
                </mat-form-field>
                @for (region of (regions | filter: searchFn: searchFilter.region); track region) {
                  <mat-option
                    [value]="region">{{ region.name }}
                  </mat-option>
                }
              </mat-select>
            }
          }
        </mat-form-field>
      </div>
    }

    @if (provinces) {
      <div class="col-12 col-md-6 my-3">
        <mat-form-field>
          <mat-label>{{ 'province' | translate }}</mat-label>
          @switch (!!provinces.length) {
            @default {
              <input formControlName="province" matInput>
            }
            @case (true) {
              <mat-select #provinceSelect auto-open (ngModelChange)="setCities($event)" panelWidth=""
                          formControlName="province" position-top-mobile (openedChange)="focusInput($event, provinceInput)">
                <mat-form-field appearance="fill" class="dropdown-search-filter">
                  <input type="text" matInput [(ngModel)]="searchFilter.province"
                         (keyup.arrowDown)="focusOptions(provinceSelect)" #provinceInput autocomplete="off"
                         [ngModelOptions]="{standalone:true}">
                  @if (searchFilter.province) {
                    <button matSuffix mat-icon-button (click)="searchFilter.province=''"></button>
                  }
                </mat-form-field>
                @for (province of (provinces | filter: searchFn: searchFilter.province); track province) {
                  <mat-option
                    [value]="province">{{ province.name }}
                  </mat-option>
                }
              </mat-select>
            }
          }
        </mat-form-field>
      </div>
    }

    @if (cities) {
      <div class="col-12 col-md-6 my-3">
        <mat-form-field>
          <mat-label>{{ 'city' | translate }}</mat-label>
          @switch (!!cities.length) {
            @default {
              <input formControlName="city" matInput>
            }
            @case (true) {
              <mat-select #citySelect auto-open formControlName="city" position-top-mobile panelWidth=""
                          (openedChange)="focusInput($event, cityInput)">
                <mat-form-field appearance="fill" class="dropdown-search-filter">
                  <input type="text" matInput [(ngModel)]="searchFilter.city" (keyup.arrowDown)="focusOptions(citySelect)"
                         #cityInput autocomplete="off" [ngModelOptions]="{standalone:true}">
                  @if (searchFilter.city) {
                    <button matSuffix mat-icon-button (click)="searchFilter.city=''">
                    </button>
                  }
                </mat-form-field>
                @for (city of (cities | filter: searchFn: searchFilter.city); track city) {
                  <mat-option
                    [value]="city">{{ city.name }}
                  </mat-option>
                }
              </mat-select>
            }
          }
        </mat-form-field>
      </div>
    }

  </ng-container>
  <ng-content></ng-content>
</ng-template>

<!--  <mat-label *ngIf="label" class="col-12 p-0 mt-4" translate>{{ label }}</mat-label>-->
<div class="row g-2">
  <ng-template [ngTemplateOutletContext]="{$implicit: isDisabled ? birthPlace.value : birthPlace}"
               [ngTemplateOutlet]="isDisabled ? staticTpl: fillTpl"></ng-template>
</div>


