import { Component, Input, OnInit } from '@angular/core';
import { SvgIconDirective } from '@bs/common/directives/svg-icon.directive';
import { SmarticoService } from '@bs/services/services/core/smartico.service';
import { SmarticoProps } from '@bs/universal/smartico';
import { TranslateModule } from '@ngx-translate/core';
import { DeepLinksComponent } from '../deep-links/deep-links.component';

@Component({
  selector: 'smartico-bar',
  templateUrl: './smartico-bar.component.html',
  styleUrls: ['./smartico-bar.component.scss'],
  standalone: true,
  imports: [TranslateModule, DeepLinksComponent, SvgIconDirective]
})

export class SmarticoBarComponent implements OnInit {

  currentProps: SmarticoProps = {};

  @Input()
  iconSize = 22;

  constructor(private smartico: SmarticoService) {
    this.smartico.isReady$.subscribe((smartico) => {
      smartico.on('props_change', (change: SmarticoProps) => {
        Object.assign(this.currentProps, change);
      });
    });
  }

  ngOnInit() {
  }
}
