import { Directive } from '@angular/core';
import { MatSelect } from '@angular/material/select';

/**
 * this directive automatically opens and focuses material select dropdown
 */
@Directive({
  selector: 'mat-select[auto-open]'
})
export class MatAutoOpenDirective {

  /**
   * The Constructor
   *
   * @param select {MatSelect} reference to material select dropdown methods
   */
  constructor(select: MatSelect) {
    const nativeEl = select._elementRef.nativeElement;
    nativeEl.addEventListener('focus', () => select.open());

    select.selectionChange.subscribe({
      next: () => {
        select.close();
        nativeEl.focus();
      }
    });

  }
}

/**
 * Example usage:
 *
 * @example
 *  <mat-select #regionSelect auto-open>...</mat-select>
 */
