<ng-container [ngTemplateOutlet]="isMobile ? mobileTpl : desktopTpl"></ng-container>

<ng-template #mobileChatBtnTpl>
  <mobile-chat-btn class="d-block p-md-2">
    <div class="chat-text d-flex align-items-center">
      <svg icon="chat-outline" lib="global" [size]="{w: 18, h: 18}" class="me-1"></svg>
      <span translate>liveChat</span>
    </div>
  </mobile-chat-btn>
</ng-template>

<ng-template #lineChatTpl>
  <div class="p-md-2">
    <a [href]="lineChat" target="_blank" mat-icon-button color="primary">
      <div class="chat-text d-flex align-items-center">
        <svg icon="line-chat" lib="global" [size]="{w: 18, h: 18}" class="me-1"></svg>
        <span translate>lineChat</span>
      </div>
    </a>
  </div>
</ng-template>

<ng-template #desktopTpl>
  <ng-container [ngTemplateOutlet]="menuStatus.section ? expandedTpl : shrinkedTpl"></ng-container>

  <ng-template #expandedTpl>
    <div class="sidebar-menu expanded">
      <a [routerLink]="['/']" class="d-flex justify-content-center">
        <img src="assets/images/logo.svg" class="logo">
        <div class="logo-background d-none"></div>
      </a>
      @if (me?.typeId === 0) {
        <ng-content select="smartico-sidebar"></ng-content>
      }

      @if (mainMenu) {
        <!--        <span class="title">{{mainMenu.label}}</span>-->
        <mat-divider></mat-divider>
        <menu [menu]="mainMenu" class="main-menu"></menu>
      }

      @if (serviceMenu) {
        <!--        <span class="title">{{serviceMenu.label}}</span>-->
        <mat-divider></mat-divider>
        <menu [menu]="serviceMenu" class="service-menu"></menu>
      }

      <ng-container [ngTemplateOutlet]="mobileChatBtnTpl"></ng-container>

      @if (lineChat) {
        <ng-container [ngTemplateOutlet]="lineChatTpl"></ng-container>
      }
    </div>
  </ng-template>

  <ng-template #shrinkedTpl>
    <div class="sidebar-menu">
      <a [routerLink]="['/']" class="d-flex justify-content-center">
        <img src="assets/images/logo-small.svg" class="logo-small">
        <div class="logo-background d-none"></div>
      </a>
    </div>
  </ng-template>
</ng-template>

<ng-template #menuTpl let-menu>
  @if (me?.typeId === 0) {
    <smartico-sidebar></smartico-sidebar>
  }
  <menu [menu]="menu" class="mobile-menu"></menu>
  @if (loadMenu === 'main') {
    <dropdown-language class="nav-item"></dropdown-language>
    @if (hasChat) {
      <li class="nav-item leaf">
        <ng-container [ngTemplateOutlet]="mobileChatBtnTpl"></ng-container>
      </li>
    }
    @if (lineChat) {
      <li class="nav-item leaf">
        <ng-container [ngTemplateOutlet]="lineChatTpl"></ng-container>
      </li>
    }
  }
  <!--  <ng-content select="smartico-sidebar"></ng-content>-->
</ng-template>

<ng-template #mobileTpl>
  <div class="sidebar-menu-mobile">
    <ng-template [ngTemplateOutlet]="menuTpl"
                 [ngTemplateOutletContext]="{$implicit: loadMenu === 'main' ? mainMenu:accountMenu}"></ng-template>
  </div>
</ng-template>
