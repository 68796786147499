<div class="row gap-3">
  <div class="col-4">
    <mat-form-field>
      <mat-label translate>day</mat-label>
      <mat-select [(ngModel)]="date" (change)="modelChanged(date, 'date')" [disabled]="isDisabled" id="day" required>
        @for (d of dates;track d) {
          <mat-option [value]="d">{{ d }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-4">
    <mat-form-field>
      <mat-label translate>month</mat-label>
      <mat-select [(ngModel)]="month" (ngModelChange)="modelChanged($event, 'month')" [disabled]="isDisabled" id="month" required>
        @for (z of months;track z.value) {
          <mat-option [value]="z">{{ z.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-4">
    <mat-form-field>
      <mat-label translate>year</mat-label>
      <mat-select [(ngModel)]="year" (ngModelChange)="modelChanged(year, 'year')" [disabled]="isDisabled" id="year" required>
        @for (y of years;track y) {
          <mat-option [value]="y">{{ y }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
</div>
