<!--<pre class="floating left">{{currentProps|json}}</pre>-->
<div class="d-flex smartico-container">

  <div class="d-flex align-items-center">
    <div class="props props-balance d-flex flex-row align-items-center">
      <svg icon="star" lib="global" [size]="{w:22, h:22}" class="me-1"></svg>
      <span class="me-2">{{ currentProps.ach_points_balance }}</span>
      <span translate>points</span>
    </div>
  </div>

  <!--  <deep-link name="gf_saw" [params]="{standalone:true}">-->
  <!--    <svg [size]="{w:iconSize, h:iconSize}" icon="prize-wheel" lib="menu"></svg>-->
  <!--    <div class="props">-->
  <!--      <span translate>wheel</span>-->
  <!--    </div>-->
  <!--  </deep-link>-->
  <!--  -->
  <!--  <deep-link name="gf_badges" [params]="{standalone:true}">-->
  <!--    <md-icon icon="shield" [style.font-size.px]="iconSize"></md-icon>-->
  <!--    <div class="props">-->
  <!--      <span translate>badges</span>-->
  <!--    </div>-->
  <!--  </deep-link>-->

  <!--  <deep-link name="gf_levels" [params]="{standalone:true}">-->
  <!--    <md-icon icon="chart-line-variant" [style.font-size.px]="iconSize"></md-icon>-->
  <!--    <div class="props">-->
  <!--      <span>levels</span>-->
  <!--    </div>-->
  <!--  </deep-link>-->

  <!--  <deep-link name="gf_missions" [params]="{standalone:true}">-->
  <!--    <md-icon icon="rocket" [style.font-size.px]="iconSize"></md-icon>-->
  <!--    <div class="props">-->
  <!--      <span>missions</span>-->
  <!--    </div>-->
  <!--  </deep-link>-->

  <!--  <deep-link name="gf_tournaments" [params]="{standalone:true}">-->
  <!--    &lt;!&ndash;<md-icon icon="crown" [style.font-size.px]="iconSize"></md-icon>&ndash;&gt;-->
  <!--    <svg [size]="{w:iconSize, h:iconSize}" icon="tournaments" lib="menu"></svg>-->
  <!--    <div class="props">-->
  <!--      <span>tournaments</span>-->
  <!--    </div>-->
  <!--  </deep-link>-->
  <!--  -->
  <deep-link name="inbox" [params]="{standalone:true}">
    <div class="py-2 d-flex align-items-center">
      <svg icon="email-outline" lib="global" [size]="{w:22, h:22}"></svg>
    </div>
  </deep-link>
</div>
