import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatCard, MatCardContent } from '@angular/material/card';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'pre-footer',
  templateUrl: 'pre-footer.component.html',
  styleUrls: ['pre-footer.components.scss'],
  standalone: true,
  imports: [MatCard, MatCardContent, MatButton]
})

export class PreFooterComponent {
  fullView: boolean;
  html: string = '';

  constructor(private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.route.data.subscribe((data: { data: any }) => {
        //console.log(data)
        //this.html = 'stai navigando la pagina: ' + this.router.url
      });
    });
  }

  changeView() {
    this.fullView = !this.fullView;
  }
}

