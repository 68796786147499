import { Component } from '@angular/core';
import { Params, Router } from '@angular/router';
import { IMe } from '@bs/models/me/me';
import { AuthService } from '@bs/services/services/core/auth.service';
import { SmarticoService } from '@bs/services/services/core/smartico.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'smartico-sidebar',
  templateUrl: 'smartico-sidebar.component.html',
  styleUrls: ['smartico-sidebar.component.scss'],
  standalone: true,
  imports: [TranslateModule]
})

export class SmarticoSidebarComponent {
  ready = false;
  subs = new Subscription();
  me: IMe;

  constructor(public translate: TranslateService,
              private router: Router,
              private auth: AuthService, private smarticoService: SmarticoService) {
    this.translate.currentLang;

    this.smarticoService.isReady$.subscribe(() => {
      this.ready = true;
    });
    this.subs.add(auth.accountLogged$.subscribe(
      {
        next: me => {
          if (me) {
            this.me = me;

          } else {
            this.me = null;
          }
        }
      }));

  }

  action(dp: string, params?: Params) {
    if (this.auth.isLogged()) {
      this.smarticoService.dp(dp, params);
    } else {
      void this.router.navigate([], { fragment: 'login' });
    }
  }

}
