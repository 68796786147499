<ng-container [ngTemplateOutlet]="isMobile ? mobileTpl : desktopTpl"></ng-container>
<ng-template #desktopTpl>
  <main>
    <div class="main-grid-system" [class.expanded]="menuStatus.section">
      <div class="left-side">
        <sidebar-menu-maggie [isMobile]="isMobile">
          <smartico-sidebar></smartico-sidebar>
        </sidebar-menu-maggie>
      </div>
      <div class="right-side">
        <app-header [isMobile]="isMobile">
          <smartico-bar></smartico-bar>
        </app-header>
        <div class="min-vh mx-auto" [class.max-vw]="!isMobile">
          <router-outlet (activate)="window.scrollTo(0, 0)"></router-outlet>
        </div>
        <pre-footer></pre-footer>
        <footer></footer>
      </div>
    </div>
  </main>
</ng-template>

<ng-template #mobileTpl>
  <app-header [isMobile]="isMobile"></app-header>
  <mat-drawer-container [autosize]="false" hasBackdrop="false">
    <mat-drawer [(opened)]="menuStatus.main" mode="over" position="start">
      <sidebar-menu-maggie class="sidebar-menu-main" loadMenu="main" [isMobile]="isMobile"></sidebar-menu-maggie>
    </mat-drawer>
    <mat-drawer [(opened)]="menuStatus.section" mode="over" position="end">
      <sidebar-menu-maggie loadMenu="section" [isMobile]="isMobile"></sidebar-menu-maggie>
    </mat-drawer>
    <mat-drawer-content>
      <main class="mobile-container">
        <router-outlet (activate)="window.scrollTo(0, 0)"></router-outlet>
      </main>
      <pre-footer></pre-footer>
      <footer></footer>
    </mat-drawer-content>
  </mat-drawer-container>
</ng-template>
