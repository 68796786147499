import { LowerCasePipe, SlicePipe } from '@angular/common';
import { Component, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { SvgIconDirective } from '@bs/common/directives/svg-icon.directive';
import { BaseMobilePrefix } from '@bs/forms/base/base-mobile-prefix';
import { RestrictInputDirective } from '@bs/forms/directives/restrict-input.directive';

/**
 * The component contains the prefix of country, and the input field that let us enter a phone number
 */
@Component({
  selector: 'mobile-prefix',
  templateUrl: './mobile-prefix.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MobilePrefixComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [MatFormField, MatSelect, FormsModule, MatOption, MatInput, LowerCasePipe, SlicePipe, SvgIconDirective, RestrictInputDirective]
})
export class MobilePrefixComponent extends BaseMobilePrefix {
}

