import { NgTemplateOutlet } from '@angular/common';
import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatError, MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { SvgIconDirective } from '@bs/common/directives/svg-icon.directive';
import { FilterPipe } from '@bs/common/pipes/filter.pipe';
import { HasPermissionPipe } from '@bs/common/pipes/hasPermission.pipe';
import { BaseGeoInfos } from '@bs/forms/base/base-geo-infos';
import { ICountry } from '@bs/models/common/country';
import { GeoInfoType } from '@bs/models/me/account';
import { IAccountMePermissions } from '@bs/models/me/enumPermission';
import { TranslateModule } from '@ngx-translate/core';
import { FieldErrorComponent } from '../field-error/field-error.component';

/**
 * Component renders 2 templates, one with the saved geographic information of user, other with the input fields to fill in the user information
 *
 * @description contains fields: city, country, region, province, address, zip code
 */
@Component({
  selector: 'geo-infos',
  templateUrl: './geo-infos.component.html',
  styleUrls: ['./geo-infos.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GeoInfosComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [MatFormField, MatLabel, MatInput, MatButton, FormsModule, ReactiveFormsModule, MatSelect, MatIconButton, MatSuffix, MatOption, MatError, FieldErrorComponent, NgTemplateOutlet, MatTabGroup, MatTab, TranslateModule, HasPermissionPipe, FilterPipe, SvgIconDirective]
})
export class GeoInfosComponent extends BaseGeoInfos implements OnDestroy, OnInit {
  /**
   * if true, we create a geo info form
   */
  @Input()
  isRegistration = false;
  /**
   * users permission reference
   */
  permission = IAccountMePermissions;

  /**
   * when dropdown is opened we set the focus on the search input field
   * @param opened
   * @param input
   */
  dropdownOpened(opened: boolean, input: HTMLInputElement): void {
    if (opened) {
      input.focus();
    }
  }

  /**
   * sets the geoinfos types tabs, if is registration we generate the geoinfos form in runtime
   *
   * see {@link GeoInfoType} for more info of typekeys values
   */
  ngOnInit() {
    this.typeKeys = this.typeKeys || this.types.values().filter(g => g.key !== GeoInfoType.Headquarters);

    if (this.isRegistration) {
      setTimeout(() => this.addGeoInfo(GeoInfoType.Residence), 0);
    }
  }

  searchFn = (items: ICountry, search: string) => {
    if (!search) {
      return items;
    }
    return items.name.toLowerCase().includes(search);
  };

}
