import { Component, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { BaseCausalGroups } from '@bs/forms/base/base-causal-groups';
import { TranslateModule } from '@ngx-translate/core';

/**
 * The component contains the causal, and causalGroup field
 */
@Component({
  selector: 'causal-groups',
  templateUrl: './causal-groups.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CausalGroupsComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [MatFormField, MatLabel, MatSelect, FormsModule, MatOption, TranslateModule]
})
export class CausalGroupsComponent extends BaseCausalGroups {
}
