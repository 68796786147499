import { Routes } from '@angular/router';
import { LangGuard } from '@bs/services/guards/lang.guard';
import { TranslateGuard } from '@bs/services/guards/translate.guard';
import { UserLoggedGuard } from '@bs/services/guards/user-logged.guard';
import { BookmakersResolver } from '@bs/services/resolvers/core/bookmakers.resolver';
import { CurrenciesResolver } from '@bs/services/resolvers/core/currencies.resolver';
import { SlidersResolver } from '@bs/services/resolvers/core/sliders.resolver';
import { environment } from '../environments/environment';

const optionalRoutes: Routes = [];

optionalRoutes.push({
  path: 'sports',
  loadChildren: () => import('./pages/sports/sports.module').then(m => m.SportsModule)
})

optionalRoutes.push({
  path: 'e-sports',
  loadChildren: () => import('./pages/e-sports/e-sports.module').then(m => m.ESportsModule)
})

if (environment.api.games) {
  optionalRoutes.push({
    path: 'games',
    loadChildren: () => import('./pages/games/games.module').then(m => m.GamesModule)
  });
}

// if (environment.api.sportsbook) {
//   optionalRoutes.push({
//     path: 'sportsbook',
//     loadChildren: () => import('./pages/sportsbook/sportsbook.module').then(m => m.SportsbookModule)
//   })
// }

if (environment.api.promo) {
  optionalRoutes.push({
    path: 'promotions',
    data: {
      section: 'promotions'
    },
    loadChildren: () => import('./pages/promotions/promotions.module').then(m => m.PromotionsModule)
  });
}

export const appRoutes: Routes = [
  {
    path: '',
    canActivate: [TranslateGuard],
    children: [],
    pathMatch: 'full'
  },
  {
    path: ':lang',
    resolve: {currencies: CurrenciesResolver, sliders: SlidersResolver},
    data: {
      langs: environment.langs,
    },
    canActivate: [LangGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: environment.homePage
      },
      {
        path: 'logout',
        loadComponent: () => import('./pages/logout/logout.page').then(m => m.LogoutPage),
        title: 'seo-logout-page-title'
      },
      {
        path: 'email-confirmation',
        loadComponent: () => import('./pages/email-confirmation/email-confirmation.page').then(m => m.EmailConfirmationPage),
        title: 'seo-email-confirmation-page-title'
      },
      {
        path: 'rp',
        loadComponent: () => import('./pages/recovery-password/recovery-password.page').then(m => m.RecoveryPasswordPage),
        title: 'seo-recovery-password-page-title'
      },
      {
        path: 'download',
        loadComponent: () => import('./pages/download/download.page').then(m => m.DownloadPage),
        title: 'seo-download-page-title'
      },
      {
        path: 'loyalty',
        loadComponent: () => import('./pages/loyalty/loyalty-system.page').then(c => c.LoyaltySystemPage),
        title: 'seo-loyalty-page-title'
      },
      {
        path: 'cashback',
        loadComponent: () => import('./pages/cashback/cashback.page').then(m => m.CashbackPage),
        title: 'seo-cashback-page-title'
      },
      {
        path: 'vip',
        loadComponent: () => import('./pages/vip/vip.page').then(m => m.VipPage),
        title: 'seo-vip-page-title',
        data: {
          name: 'vip'
        }
      },
      {
        path: 'affiliates',
        loadComponent: () => import('./pages/affiliate/affiliate.page').then(c => c.AffiliatePage),
        title: 'seo-affiliate-page-title',
        resolve: {
          sliders: SlidersResolver
        }
      },
      {
        path: 'not-authorized',
        loadComponent: () => import('./pages/errors/errors.page').then(c => c.ErrorsPage),
        //title: 'seo-not-authorized-page-title',
        data: {
          icon: 'ban',
          backBtn: true,
          title: 'not-authorized-page-title',
          description: 'not-authorized-page-description'
        }
      },
      {
        path: 'auto-logout',
        data: {
          backBtn: true,
          //description: 'auto-login-page-description',
          icon: 'auto-logout',
          //title: 'auto-login-page-title'
        },
        loadComponent: () => import('./pages/errors/errors.page').then(c => c.ErrorsPage),
      },
      {
        path: 'register',
        loadComponent: () => import('./pages/register/register.page').then(m => m.RegisterPage),
        canActivate: [UserLoggedGuard],
        title: 'seo-register'
      },
      {
        path: 'me',
        loadChildren: () => import('./pages/me/me.module').then(m => m.MeModule)
      },
      {
        path: 'accounts',
        resolve: {
          bookmakers: BookmakersResolver
        },
        loadChildren: () => import('./pages/accounts/accounts.module').then(m => m.AccountsModule)
      },
      {
        path: 'cms',
        data: {
          section: 'cms'
        },
        loadChildren: () => import('./pages/cms/cms.module').then(m => m.CmsModule)
      },
      ...optionalRoutes,
      {
        path: '**',
        redirectTo: '404',
      },
      {
        path: '404',
        data: {
          backBtn: true,
          description: 'not-found-page-description',
          icon: '404',
          title: 'not-found-page-title'
        },
        loadComponent: () => import('./pages/errors/errors.page').then(c => c.ErrorsPage),
      }
    ]
  }
];
