import { IPrefix } from '@bs/models/common/prefix';

export class IMobilePrefix {
  phone: string;
  prefix: IPrefix;

  constructor(phone: string, prefix: IPrefix) {
    this.phone = phone;
    this.prefix = prefix;
  }
}
