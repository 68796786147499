import { Directive, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { WINDOW } from '@bs/universal/window.service';
import { Subscriber } from 'rxjs';

/**
 * the directive positions the options of mat-select at the top of the select
 */
@Directive({
  selector: 'mat-select[position-top-mobile]'
})
export class SelectMobilePositionTopDirective implements OnInit, OnDestroy {

  subs = new Subscriber();

  /**
   * The Constructor
   *
   * @param select {MatSelect} reference to material select dropdown methods
   * @param window {Window} reference to window
   */
  constructor(@Inject(WINDOW) private window: Window, private select: MatSelect) {

  }

  /**
   * positions the mat select dropdown at the top
   */
  ngOnInit() {
    this.subs.add(this.select.openedChange.subscribe({
      next: isOpen => {
        if (isOpen) {
          // this.select._offsetY = -260; TODO check from new material
        }
      }
    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
