import { CurrencyPipe } from '@angular/common';
import { APP_ID, APP_INITIALIZER, ApplicationConfig, importProvidersFrom, isDevMode, Provider } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { coreProviders } from '@bs/common/core.providers';
import { TranslationModule } from '@bs/common/translation.module';
import { CordovaService } from '@bs/services/services/core/cordova.service';
import { WebappService } from '@bs/services/services/endpoint/webapp.service';
import { universalProviders } from '@bs/universal/universal.providers';
import { MaterialComponentsModule } from '@homer/mat-components';
import { WidgetIsReadyService } from '@livechat/widget-angular';
import { register } from 'swiper/element/bundle';
import { environment } from '../environments/environment';
import { appRoutes } from './app-routes';

register();

const optionalProviders: Array<Provider> = [];

if (environment.liveChats?.liveChatInc) {
  optionalProviders.push(WidgetIsReadyService);
}

if (environment.mobileApp) {
  optionalProviders.push(CordovaService);
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      // Internal
      MaterialComponentsModule.forRoot(environment),
      TranslationModule.forRoot()
      //GamesModule // todo: should not be here
    ),
    ...optionalProviders,
    WebappService,
    coreProviders(environment),
    universalProviders(),
    provideRouter(appRoutes),
    provideServiceWorker('ngsw-worker.js', { enabled: !isDevMode() && !environment.mobileApp, registrationStrategy: 'registerWhenStable:30000' }),
    {
      provide: APP_ID,
      useValue: environment.project.split('.')[0]
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [WebappService],
      useFactory: (appConfigService: WebappService) => () => appConfigService.webapp()
    },
    CurrencyPipe,
    provideAnimations()
  ]
};
