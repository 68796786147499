import { Component, Inject } from '@angular/core';
import { MatCard, MatCardTitle } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { RouterLink } from '@angular/router';
import { SvgIconDirective } from '@bs/common/directives/svg-icon.directive';
import { WindowService } from '@bs/universal/window.service';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { RegistrationComponent } from '../../registration/registration.component';

@Component({
  selector: 'registration-dialog',
  templateUrl: './registration.dialog.html',
  styleUrls: ['./registration.dialog.scss'],
  standalone: true,
  imports: [MatCard, MatCardTitle, RouterLink, MatProgressSpinner, RegistrationComponent, TranslateModule, SvgIconDirective]
})

export class RegistrationDialogComponent {

  isMobile: any;

  subs = new Subscription();
  loading: boolean;

  constructor(public dialogRef: MatDialogRef<RegistrationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              windowService: WindowService) {


    this.subs.add(windowService.device$.subscribe({
      next: device => this.isMobile = device.isMobile
    }));
  }

  onRegistrationSuccess() {
    this.dialogRef.close();
  }

}
