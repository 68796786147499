import { Component, EventEmitter, HostBinding, Injector, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Ages } from '@bs/models/common/ages';
import { AppSettings } from '@bs/models/common/app-settings';
import { IInput, InputType } from '@bs/models/common/forms';
import { IdName } from '@bs/models/common/id-name';
import { AppSettingsService } from '@bs/services/services/core/app-settings.service';

/**
 *  \    / /\  |_) |\ |  |  |\ | /__
 *   \/\/ /--\ | \ | \| _|_ | \| \_|
 *
 *  DUE TO THE IMPACT THIS FILE HAS ON THE PLATFORM DO NOT CHANGE ANYTHING AND REFER TO YOUR TEAM LEADER
 * */
@Component({
  template: ``,
  standalone: true
})
export class BaseField implements ControlValueAccessor, OnChanges {
  /**
   * when input type is hidden, we set a host class hidden which will hide the input field
   *
   * @description it applies "display: none" to the field
   */
  @HostBinding('class.hidden')
  isHidden: boolean;
  /**
   * receives and object with key (the name of the field to populate)
   */
  @Input()
  values: Array<IdName<any, any>>;

  @Input()
  inputId!: string;

  /**
   * input information for the field
   *
   * @description for example: name of the field, type, validations, dropdown values and many more
   *
   * see {@link IInput} for the properties of the model
   */
  @Input()
  input: IInput;
  /**
   * The input type field
   *
   * see {@link InputType} for the properties of the model
   */
  @Input()
  type: InputType;
  /**
   * sets the name, id, placeholder and label for the field
   */
  @Input()
  formControlName: string;
  /**
   * sets a tooltip message for a certain field
   */
  @Input()
  tooltip: string;

  @Output() blur = new EventEmitter<any>(null);
  /**
   * keeps track of value and user interaction of the control and keep the view synced with the model
   */
  model: any;
  /**
   * disables the field
   */
  isDisabled: boolean;
  /**
   * local reference to NgControl
   */
  control: NgControl;
  /**
   * local reference to Ages values
   *
   * see {@link Ages} the key and values of Ages
   */
  ages = Ages;
  /**
   * max date allowed for a date field
   */
  maxDate: Date;
  /**
   * min date allowed for a date field
   */
  minDate: Date;
  /**
   * local reference of AppSettings
   */
  public settings: AppSettings;

  /**
   * The constructor setting the settings variable
   * @param appSettingsService
   * @param injector
   * @param route
   */
  constructor(appSettingsService: AppSettingsService, private injector: Injector, protected route: ActivatedRoute) {
    appSettingsService.appSettings$.subscribe({
      next: ({ settings }) => this.settings = settings
    });
  }

  /**
   * lifecycle hook, when it detects changes of input decorator values variable, it updates the model value on change of queryParams or when some programmatic changes been done in parent component
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.values?.currentValue && !this.input?.values?.observers) {
      if ((this.input?.autoselect || this.values.length === 1) && !this.route.snapshot.queryParamMap.has(this.input.name) && this.input?.type !== 'geo-infos') {
        setTimeout(() => {
          // todo: check if there is a case for geo-infos
          switch (this.input.type) {
            case 'multi-select': // gets the autoselect key from settings if defined, otherwise the first value
              this.model = [this.input.autoselect?.key ? this.settings[this.input.autoselect.key].id : this.values[0]?.id];
              break;
            case 'select': // gets the autoselect key from settings if defined, otherwise the first value
              this.model = this.input.autoselect?.key ? this.settings[this.input.autoselect.key].id : this.values[0]?.id;
              break;
            default:
              this.model = this.values[0]?.id ? this.values[0].id : this.values[0];
              break;
          }

          this.propagateChange(this.model);
        }, 100);
      } else if (this.values.some(x => x.selected)) {
        setTimeout(() => {
          const elm = this.values.find(x => x.selected);
          this.model = this.input.type === 'select' ? elm : elm.id;
          this.propagateChange(this.model);
        }, 100);
      } else {
        this.model = '';
      }
    } else {
      // is an observable
      if (this.input.autoselect && this.input?.values?.observers) {
        this.input.values.subscribe(x => {
          setTimeout(() => {
            this.model = x[0].id;
          }, 100);
        });
      }
    }
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  /**
   * updates the model value, when programmatic changes from model to view are requested
   * @param obj
   */
  writeValue(obj: any): void {
    //if (obj != null) {
    this.model = obj;
    // }
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  propagateChange(_model: any) {
  }

  /**
   * function that's called on blur effect of input
   */
  onBlur($event: Event) {
    this.blur.emit($event);
    this.onTouched();
  }

  /**
   * on field when ngModelChanges it's value, we propagate the value change and register touched field
   * @param model
   */
  update(model: any) {
    this.onTouched();
    this.propagateChange(model);
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   */
  onTouched() {
  }

  /**
   * initializing default input field name, type, label, placeholder, minDate and maxDate values
   * @protected
   */
  protected _ngOnInit() {
    this.control = this.injector.get(NgControl);
    if (!this.input) {
      this.inputId = this.inputId ?? this.formControlName;
      this.input = {
        type: this.type,
        name: this.formControlName,
        label: this.formControlName
      };
    }
    this.isHidden = this.input.type === 'hidden';
    this.input.placeholder = this.input?.floating ? ' ' : this.input?.placeholder || this.formControlName;

    if (this.input.options?.max) {
      this.maxDate = this._fromJSDate(this.input.options.max);
    }

    if (this.input.options?.min) {
      this.minDate = this._fromJSDate(this.input.options.min);
    }
  }

  /**
   * function that sets the dates values, by corresponding Ages values
   *
   * see {@link Ages} from more information of date values
   * @param jsDate
   * @protected
   */
  protected _fromJSDate(jsDate: string | Date): Date {

    if (!jsDate) {
      return;
    }

    if (typeof jsDate === 'string') {

      const couldBeDate = new Date(jsDate);

      if (isNaN(couldBeDate.getTime())) {
        jsDate = this.ages[jsDate] as Date;
      } else {
        jsDate = couldBeDate as Date;
      }
    }

    return jsDate;
  }
}
