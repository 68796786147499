<ng-content></ng-content>
<!--<div class="w-100">-->
<!--<label *ngIf="input.type !== 'checkbox' && input.label" [class.req]="input.validations?.required" [for]="input.name">{{input.label | translate}}</label>-->

<div [ngClass]="control.errors && control.touched ? 'my-3' : 'mt-3'">

  <ng-content select=".pre"></ng-content>

  @switch (input.type) {
    @default {
      <mat-form-field>
        <mat-label><span [class.required]="input.validations?.required">{{ input.label | translate }}</span></mat-label>
        <input (blur)="onBlur($event)" (ngModelChange)="propagateChange($event)" [restrict]="input?.restrict"
               [(ngModel)]="model" [disabled]="isDisabled" [id]="input.name" [matTooltip]="tooltip"
               [name]="input.name" [placeholder]="input.placeholder | translate" [readonly]="input.readOnly"
               [type]="input.type"
               matInput>
      </mat-form-field>
    }
    @case('hidden'){
      <input  [(ngModel)]="model" [disabled]="isDisabled" [id]="input.name" [name]="input.name"  type="hidden">
    }
    @case ('date') {
      <mat-form-field [appearance]="input?.options?.appearance">
        <mat-label><span [class.required]="input.validations?.required">{{ input.label | translate }}</span></mat-label>
        <input (click)="picker.open()" (blur)="onBlur($event)" (ngModelChange)="propagateChange($event)"
               [readonly]="input.readOnly" [(ngModel)]="model" [disabled]="isDisabled" [id]="input.name" [max]="maxDate"
               [min]="minDate"
               [matDatepicker]="picker" [name]="input.name" matInput>
        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    }
    @case ('checkbox') {
      <mat-checkbox (blur)="onBlur($event)" (ngModelChange)="propagateChange($event)"
                    [(ngModel)]="model" [disabled]="isDisabled" [id]="input.name" [name]="input.name">
        @if (input.url) {
          <span [class.required]="input.validations?.required" [innerHTML]="input.url | translate"></span>
        } @else {
          <span [class.required]="input.validations?.required">{{ input.label | translate }}</span>
        }
      </mat-checkbox>
    }
    @case ('date-range') {
      <date-range (blur)="onBlur($event)" (ngModelChange)="propagateChange($event)" [minDate]="minDate"
                  [maxDate]="maxDate" [(ngModel)]="model" [disabled]="isDisabled" [id]="formControlName"
                  [name]="formControlName"></date-range>
    }
    @case ('mobile-prefix') {
      <mobile-prefix (ngModelChange)="propagateChange($event)" (blur)="onBlur($event)"
                     [(ngModel)]="model" [disabled]="isDisabled" [id]="input.name" [name]="input.name"
                     [placeholder]="input.placeholder"></mobile-prefix>
    }
    @case ('password') {
      <mat-form-field>
        <mat-label><span [class.required]="input.validations?.required">{{ input.label | translate }}</span></mat-label>
        <input (blur)="onBlur($event)" (ngModelChange)="propagateChange($event)" [restrict]="input?.restrict"
               [(ngModel)]="model" [disabled]="isDisabled" [id]="input.name" [autocomplete]="input.autocomplete"
               [name]="input.name" [type]="toggle?'text':'password'"
               matInput>
        <button (click)="toggle = !toggle" [disabled]="isDisabled" tabindex="-1" type="button" mat-icon-button
                matSuffix>
          <svg [icon]="toggle ? 'eye' : 'eye-off'" lib="global" [size]="{w:13, h:13}"></svg>
        </button>
      </mat-form-field>
    }
    @case ('causal-groups') {
      <causal-groups (blur)="onBlur($event)" (ngModelChange)="propagateChange($event)" [(ngModel)]="model"
                     [disabled]="isDisabled" [id]="formControlName"
                     [name]="formControlName"></causal-groups>
    }
    @case ('radio') {
      <mat-radio-group [(ngModel)]="model" (ngModelChange)="propagateChange($event)" [disabled]="isDisabled">
        @for (option of values;track option) {
          <mat-radio-button [value]="option.id">{{ option.name | translate }}</mat-radio-button>
        }
      </mat-radio-group>
    }
    @case ('slide-toggle') {
      <mat-slide-toggle (ngModelChange)="propagateChange($event)" [(ngModel)]="model" [disabled]="isDisabled"
                        [id]="input.name" [name]="input.name">
        <span [class.required]="input.validations?.required">{{ input.label | translate }}</span>
      </mat-slide-toggle>
    }
    @case ('textarea') {
      <mat-form-field>
        <mat-label><span [class.required]="input.validations?.required">{{ input.label | translate }}</span></mat-label>
        <textarea #autosize="cdkTextareaAutosize" (blur)="onBlur($event)"
                  (ngModelChange)="propagateChange($event)" [(ngModel)]="model" [disabled]="isDisabled"
                  [id]="input.name"
                  [name]="input.name" [placeholder]="input.placeholder | translate"
                  cdkAutosizeMaxRows="5" cdkAutosizeMinRows="1" cdkTextareaAutosize
                  matInput></textarea>
      </mat-form-field>
    }
    @case ('select') {
      <mat-form-field>
        <mat-label><span [class.required]="input.validations?.required">{{ input.label | translate }}</span></mat-label>
        <mat-select [(ngModel)]="model" [disabled]="isDisabled" [name]="input.name"
                    (ngModelChange)="propagateChange($event)">
          @if (values?.length > 1 && !input.autoselect && !input.options?.autoselect) {
            <mat-option value="">{{ 'all' | translate }}</mat-option>
          }
          @for (value of values;track value) {
            <mat-option [value]="value.id">{{ value.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    }
    @case ('date-selector') {
      <date-selector [(ngModel)]="model" (ngModelChange)="propagateChange($event)" [disabled]="isDisabled" [min]="minDate" [max]="maxDate"></date-selector>
    }
    @case ('geo-infos') {
      <geo-infos (ngModelChange)="propagateChange($event)" [(ngModel)]="model"></geo-infos>
    }
    @case ('birth-place') {
      <birth-place (ngModelChange)="propagateChange($event)" [(ngModel)]="model" [disabled]="isDisabled"></birth-place>
    }
    @case ('file') {
      <file-url [(ngModel)]="model" [options]="input?.options" (ngModelChange)="propagateChange($event)">
        <mat-label><span [class.required]="input.validations?.required">{{ input.label | translate }}</span></mat-label>
      </file-url>
    }
  }

  <ng-content select=".suff"></ng-content>
</div>
<!--</div>-->
<mat-error [control]="control"></mat-error>
