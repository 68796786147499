<div class="row flex-column flex-md-row">
  <mat-form-field class="form-group col">
    <mat-label>{{ 'fromDate' | translate }}</mat-label>
    <input (ngModelChange)="update({from:$event, to:toDate})" [(ngModel)]="fromDate" [min]="minDate"  [max]="maxDate" [disabled]="isDisabled" [matDatepicker]="pickerFrom" matInput>
    <mat-datepicker-toggle [for]="pickerFrom" matSuffix></mat-datepicker-toggle>
    <mat-datepicker #pickerFrom></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="form-group col mt-3 mt-md-0">
    <mat-label>{{ 'toDate' | translate }}</mat-label>
    <input (ngModelChange)="update({to:$event, from:fromDate})" [(ngModel)]="toDate" [min]="minToDate" [max]="maxDate" [disabled]="isDisabled" [matDatepicker]="pickerTo" matInput>
    <mat-datepicker-toggle [for]="pickerTo" matSuffix></mat-datepicker-toggle>
    <mat-datepicker #pickerTo></mat-datepicker>
  </mat-form-field>
</div>
