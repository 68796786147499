<!--<pre>{{ form.value | json }}</pre>-->
@if (form) {
  <form [formGroup]="form" (ngSubmit)="submit()">
    @for (input of formConfig.inputs; track i; let i = $index) {
      <field [formControlName]="input.name" [input]="input" [values]="input?.values"></field>
    }
    <div class="mt-4 w-100">
      <button class="w-100" mat-raised-button color="primary" [disabled]="!form.valid">{{ 'submit' | translate }}</button>
    </div>
  </form>
}
