import { Component, OnInit } from '@angular/core';
import { MatCard, MatCardTitle } from '@angular/material/card';
import { MatDialogRef } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { SvgIconDirective } from '@bs/common/directives/svg-icon.directive';
import { EnvironmentConfig } from '@bs/models/common/environment-config';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownLanguageComponent } from '../../components/dropdown-language/dropdown-language.component';
import { DropdownTimezonesComponent } from '../../components/dropdown-timezones/dropdown-timezones.component';
import { ThemeSwitcherComponent } from '../../components/theme-switcher/theme-switcher.component';

@Component({
  selector: 'settings-dialog',
  templateUrl: './settings-dialog.component.html',
  standalone: true,
  imports: [MatCard, MatCardTitle, TranslateModule, RouterLink, DropdownLanguageComponent, DropdownTimezonesComponent, ThemeSwitcherComponent, SvgIconDirective]
})
export class SettingsDialog implements OnInit {

  allowThemeSwitch = this.config.features.allowThemeSwitch;

  constructor(private config: EnvironmentConfig, private dialogRef: MatDialogRef<SettingsDialog>) {
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }


}
