import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ICity, ICountry, IProvince, IRegion } from '@bs/models/common/country';
import { EnvironmentConfig } from '@bs/models/common/environment-config';
import { GeoInfoType, IGeoInfo } from '@bs/models/me/account';
import { CatalogService } from '@bs/services/services/core/catalog.service';
import { MeService } from '@bs/services/services/me/me.service';
import { WindowService } from '@bs/universal/window.service';
import { Subscription } from 'rxjs';

/**
 *  \    / /\  |_) |\ |  |  |\ | /__
 *   \/\/ /--\ | \ | \| _|_ | \| \_|
 *
 *  DUE TO THE IMPACT THIS FILE HAS ON THE PLATFORM DO NOT CHANGE ANYTHING AND REFER TO YOUR TEAM LEADER
 * */
@Component({
  template: ``,
  standalone: true
})
export class BaseGeoInfos implements ControlValueAccessor {
  /**
   * Viewport mobile
   *
   * if true, we switch some templates in the dom for mobile view
   */
  isMobile = false;
  /**
   * renders a tabs for the geo infos
   */
  @Input()
  typeKeys: Array<{ key: number, value: string }>;
  /**
   * options conditions, that hides the provinces field, or they disable some button, depending on which value is true of them
   */
  @Input()
  options: { hideProvinces?: boolean, isRemovable?: boolean, canCreate?: boolean } = { hideProvinces: false };
  /**
   * the search input fields that are inside the dropdowns when we open
   *
   * used for filtering the items of the dropdowns
   */
  searchFilter = { country: '', region: '', province: '', city: '' };
  /**
   * output decorator that emits us a message information in the parent component
   */
  @Output()
  emitter = new EventEmitter();
  /**
   * responsible for disabling a field
   */
  isDisabled: boolean;
  /**
   * @ignore
   */
  loading: boolean;
  /**
   * keeps tracks if we get IPersonBirthPlace value from writeValue method
   *
   * see {@link IPersonBirthPlace} for more details of the model
   */
  hasValues: boolean;
  /**
   * local reference of FormArray
   */
  geoInfos: FormArray;
  /**
   * local reference of GeoInfoType
   */
  types = GeoInfoType;
  /**
   * local reference of array of ICountry
   */
  countries: Array<ICountry>;
  /**
   * local reference of array of IRegion
   */
  regions: Array<Array<IRegion>> = [];
  /**
   * local reference of array of IProvince
   */
  provinces: Array<Array<IProvince>> = [];
  /**
   * local reference of array of ICity
   */
  cities: Array<Array<ICity>> = [];
  /**
   * local reference for Subscription
   */
  subs = new Subscription();

  /**
   * The constructor where we set mobile viewport, and fetch and set the countries array values
   * @param config
   * @param fb
   * @param catalogService
   * @param meService
   * @param windowService
   */
  constructor(private config: EnvironmentConfig, private fb: FormBuilder, private catalogService: CatalogService, protected meService: MeService, windowService: WindowService) {
    this.subs.add(windowService.device$.subscribe({
      next: device => this.isMobile = device.isMobile
    }));
    this.catalogService.countries().subscribe({
      next: countries => this.countries = countries
    });
  }

  /**
   * lifecycles hook, that unsubscribes from the subscription
   */
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param _fn
   */
  registerOnChange(_fn: any) {
    this.propagateChange = _fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param _fn
   */
  registerOnTouched(_fn: any) {
  }

  /**
   * creates a new geoinfos form group, when programmatic changes from model to view are requested
   * @param geoInfos
   */
  writeValue(geoInfos: Array<IGeoInfo>) {
    this.hasValues = geoInfos !== null;

    this.geoInfos = this.buildGeoInfos(geoInfos);

    this.subs.add(this.geoInfos.valueChanges.subscribe({
      next: res => this.propagateChange(res)
    }));
  }

  /**
   * creates a new geoinfos form if it matches a geoInfos typeId value
   * @param typeId
   */
  addGeoInfo(typeId: number) {
    if (this.geoInfos.value.find(x => x.typeId === typeId)) {
      return;
    }

    this.geoInfos.push(this.createGeoInfos({ typeId }));
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;

    if (isDisabled) {
      this.geoInfos.controls.forEach(c => c.disable());
    }
  }

  /**
   * fetches the regions when we change the country value in the view, and sets a new values of provinces for that country
   * @param country
   * @param i
   */
  setRegions(country: ICountry, i: number) {
    this.resetFields(i, true, true, true);
    if (!country) {
      return;
    }
    this.catalogService.regions(country).subscribe({
      next: regions => {
        this.geoInfos.at(i).get('region').enable();
        if (regions.length) {
          this.regions[i] = regions;
        } else {
          this.bypass(i, true, true, true);
        }
      },

      error: () => this.bypass(i, true, true, true)
    });
  }

  /**
   * fetches the provinces from the api, on change of region value from the view, and initialize the new provinces values
   * @param region
   * @param i
   */
  setProvinces(region: IRegion, i: number) {
    this.resetFields(i, true, true);
    if (!region) {
      return;
    }
    this.catalogService.provinces(region).subscribe({
      next: provinces => {
        this.geoInfos.at(i).get('province').enable();
        if (provinces.length) {
          this.provinces[i] = provinces;
        } else {
          this.bypass(i, true, true);
        }
      },

      error: () => this.bypass(i, true, true)
    });
  }

  /**
   * fetches the cities from the api, on change of province value from the view, and initialize the new cities
   * @param province
   * @param i
   */
  setCities(province: IProvince, i: number) {
    this.resetFields(i, true);
    if (!province) {
      return;
    }
    this.catalogService.cities(province).subscribe({
      next: cities => {
        this.geoInfos.at(i).get('city').enable();
        if (cities.length) {
          this.cities[i] = cities;
        } else {
          this.bypass(i, true);
        }
      },

      error: () => this.bypass(i, true)
    });
  }

  /**
   * function sets the visibility of the tab
   * @param tab
   */
  isVisible(tab): boolean {

    /*    if (this.typeKeys.length === 1) {
          return false;
        }*/

    if (this.geoInfos.controls.length) {
      return !this.geoInfos.controls.some(c => {
        return c.get('typeId').value === tab.key;
      });
    }

    return true;
  }

  /**
   * function removes the geographic information, from a user
   * @param geoInfo
   * @param i
   */
  removeGeoInfo(geoInfo: IGeoInfo, i: number) {
    if (geoInfo.id) {
      this.meService.deleteInfo(geoInfo.id, 'geoinfo').then(
        () => {
          this.geoInfos.removeAt(i);
          // this.snackBar.open(this.translate.instant('deleteSuccessful'), this.translate.instant('close'), {duration: 3500});
          this.emitter.emit({ message: 'deleteSuccessful' });
        },
        error => this.handleError(error));
    } else {
      this.geoInfos.removeAt(i);
      this.regions[i] = null;
      this.provinces[i] = null;
      this.cities[i] = null;
    }
  }

  /**
   * this method resets the field value, when true value is passed for one of them
   *
   * @description for example when we pass resetFields(1, true, false, false) only the cities value will be cleared
   *
   * @param r reset region
   * @param p reset province
   * @param c reset city
   * @private
   */
  private resetFields(i: number, c?: boolean, p?: boolean, r?: boolean) {

    const patch = {};

    if (r) {
      this.regions[i] = null;
      this.searchFilter.region = '';
      Object.assign(patch, { region: '' });
    }

    if (p) {
      this.provinces[i] = null;
      this.searchFilter.province = '';
      Object.assign(patch, { province: '' });
    }

    if (c) {
      this.cities[i] = null;
      this.searchFilter.city = '';
      Object.assign(patch, { city: '' });
    }

    this.geoInfos.at(i).patchValue(patch);
    // this.geoInfos.at(i).updateValueAndValidity();
  }

  /**
   * functions handles the error message from http response
   * @param error
   * @private
   */
  private handleError(error: HttpErrorResponse) {
    // this.snackBar.open(this.translate.instant(error.message), this.translate.instant('close'), {panelClass: 'error'});
    this.emitter.emit({ result: 'error', info: error });
  }

  /**
   * function builds us the geo infos form
   * @param infos
   * @private
   */
  private buildGeoInfos(infos: Array<IGeoInfo>) {
    const arr = infos ? infos.map(geoInfo => this.createGeoInfos(geoInfo)) : [];
    return this.fb.array(arr);
  }

  /**
   * function creates the form group of geo infos fields with their validations
   * @param infos
   * @private
   */
  private createGeoInfos(infos?: Partial<IGeoInfo>) {
    const group = this.fb.group({
      id: [],
      address: ['', Validators.maxLength(64)],
      country: ['', Validators.required],
      region: ['', Validators.required],
      province: [''],
      city: ['', Validators.required],
      typeId: [],
      zipCode: ['', Validators.compose([Validators.minLength(4), Validators.maxLength(16)])]
    });

    if (!this.options.hideProvinces) {
      group.get('province').setValidators(Validators.required);
    }

    if (infos.typeId === GeoInfoType.Residence) {
      group.get('address').setValidators(Validators.compose([Validators.required, Validators.maxLength(64)]));
      group.get('zipCode').setValidators(Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(16)]));
    }

    if (infos) {
      group.patchValue(infos as any);
    }

    return group;
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  private propagateChange(_model: Array<IGeoInfo>) {
  }

  /**
   * function enables the control field, when true value is passed for one of them
   *
   * @description for example when we pass bypass(true) only the cities value will be cleared and enabled
   *
   * @param c
   * @param p
   * @param r
   * @private
   */
  private bypass(i: number, c?: boolean, p?: boolean, r?: boolean) {

    if (c) {
      this.cities[i] = [];
      this.geoInfos.at(i).get('city').enable();
    }

    if (p) {
      this.provinces[i] = [];
      this.geoInfos.at(i).get('province').enable();
    }

    if (r) {
      this.regions[i] = [];
      this.geoInfos.at(i).get('region').enable();
    }
  }
}
