import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  standalone: true
})
export class FilterPipe<T> implements PipeTransform {

  transform<T>(items: Array<T>, predicate: keyof T | object | ((value: T, extras: string | boolean) => boolean), extras: string | boolean = ''): Array<T> {
    if (!items) {
      return [];
    }

    if (!predicate) {
      return items;
    }

    if (typeof predicate === 'function') {
      return items.filter(value => predicate(value, extras));
    }

    if (typeof predicate !== 'string') {
      const [key, val] = Object.entries(predicate)[0];

      return items.filter(x => x[key as keyof typeof x] === val);
    }

    return [];
  }
}
