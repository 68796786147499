<!--<pre>{{ form.value | json }}</pre>-->
<div class="dialog">
  <mat-card class="new-card flat-card">
    <mat-card-title class="box-header my-3">
      <a (click)="dialogRef.close()" [routerLink]="[]" [fragment]="null" class="close-icon me-2" type="button">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </a>
      <h2 class="mx-2">{{ 'registration-dialog-title' | translate }}</h2>
    </mat-card-title>
    <div class="dialog-wrapper">
      @if (loading) {
        <div class="loading-overlay">
          <mat-spinner [diameter]="25" color="accent"></mat-spinner>
        </div>
      }
      <div class="scrollbar" [class.disabled]="!isMobile">
        <div class="registration-wrapper p-2">
          <registration-geo (registrationSuccess)="onRegistrationSuccess()"></registration-geo>
        </div>
      </div>
    </div>
  </mat-card>
</div>
