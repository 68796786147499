<ng-template #lockedemailTpl>
  <div class="row btn-row">
    <div [innerHTML]="'login-locked' | translate" class="col text-center"></div>
  </div>
  <div class="row btn-row">
    <div class="col text-center">
      <a [href]="liveChatUrl" color="accent" mat-flat-button target="_blank">
        {{ 'live-chat' | translate }}
      </a>
    </div>
  </div>
</ng-template>

<div class="form-container">
  @if (!isLoading) {
    <form (ngSubmit)="submit()" [@fadeInOut] [formGroup]="form" autocomplete="off" class="login-container row m-0">
      <div class="login-form col-12">
        <div class="mb-3 w-100">
          <ng-container>
            <div class="row">
              <div class="col-12">
                @if (formGroupErrorMessage) {
                  <div class="alert alert-danger mb-3">
                    <span class="d-flex align-items-center">
                      {formGroupErrorMessage, select, account_pending
                        {account_pending}
                        player_not_found {player_not_found}} {{ formGroupErrorMessage }}
                    </span>
                  </div>
                }
              </div>
            </div>
            <div class="row pb-2">
              @switch (isPhoneReg) {
                @case (true) {
                  <div class="col">
                    <mobile-prefix [placeholder]="'phone' | translate" class="mat-form-field mat-primary mat-form-field-appearance-fill mat-form-field-can-float mat-form-field-should-float mat-form-field-has-label"
                                   [class.mobile-error]="form.get('username').errors"
                                   formControlName="username">
                      <mat-error [control]="form.get('username')" class="field-error" [class.mb-3]="form.get('username').errors"></mat-error>
                    </mobile-prefix>
                  </div>
                }
                @default {
                  <div class="col">
                    <mat-form-field class="w-100">
                      <mat-label [translate]="'username'">Username</mat-label>
                      <div matPrefix>
                        <svg icon="account" lib="global" [size]="{w:14, h:14}"></svg>
                      </div>
                      <input [placeholder]="'username' | translate" autocapitalize="none" autocomplete="username" autocorrect="off" formControlName="username" matInput type="text">
                      <mat-error [control]="form.get('username')"></mat-error>
                    </mat-form-field>
                  </div>
                }
              }
            </div>
            <div class="row field-password">
              <div class="col">
                <mat-form-field class="w-100">
                  <mat-label [translate]="'password'" class="pb-0">Password</mat-label>
                  <div matPrefix>
                    <svg icon="key" lib="global" [size]="{w:14, h:14}"></svg>
                  </div>
                  <input [placeholder]="'password' | translate" [type]="hide ? 'password' : 'text' " autocapitalize="none" autocomplete="current-password" autocorrect="off" formControlName="password" matInput>
                  <button (click)="hide=!hide" [color]="hide ? null : 'primary'" mat-icon-button matSuffix tabindex="-1" type="button">
                    <svg [icon]="hide ? 'eye-off':'eye'" lib="global" [size]="{w:14, h:14}"></svg>
                  </button>
                  <mat-error [control]="form.get('password')" class="field-error relative"></mat-error>
                </mat-form-field>
              </div>
            </div>
            @if (form.get('tfa'); as tfaCtrl) {
              <div class="row">
                <div class="col" formGroupName="tfa">
                  <mat-form-field class="w-100">
                    <mat-label [translate]="'otp'">otp</mat-label>
                    <input [placeholder]="'otp' | translate" formControlName="otp" matInput type="text">
                    <mat-error [control]="tfaCtrl.get('otp')"></mat-error>
                  </mat-form-field>
                </div>
              </div>
            }
            <div class="row p-t-10 p-b-20 align-items-center">
              <div class="col text-right">
                <a (click)="forgotPassword()" class="recover-pw d-flex align-items-center justify-content-end">
                  <svg icon="lock" lib="global" [size]="{w:12, h:12}" class="me-2"></svg>
                  <span [translate]="'forgot-password'"></span>
                </a>
              </div>
            </div>
            <div class="alert alert-secondary">
              <span translate="login-alert"></span>
            </div>
            <div class="row">
              <div class="col text-center">
                <button [disabled]="!form.valid" color="primary" mat-raised-button type="submit" class="button-login ">
                <div class="d-flex align-items-center">
                  <span [translate]="'login'">Login</span>
                  <svg icon="log-in" lib="global" [size]="{w:14, h:14}" class="ms-2"></svg>
                </div>
                </button>
                <div class="mt-3">
                  <span>{{ 'login-dont-have-account-question' | translate }}</span>&nbsp;
                  <a class="sign-up" [routerLink]="[]" fragment="register">{{ 'sign-up' | translate }}</a>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
  } @else {
    <div [@fadeInOut] class="col-12 loading-container py-5">
      @if (!me) {
        <div class="d-flex justify-content-center">
          <mat-spinner [diameter]="40"></mat-spinner>
        </div>
      } @else {
        <div [@fadeInOut] class="col-12 loading-container py-5">
          <div class="d-flex justify-content-center icon-container">
            <svg icon="check" lib="global" [size]="{w:14, h:14}"></svg>
          </div>
          <div class="d-flex justify-content-center">
            <p [translate]="'logged-in'">Logged in!</p>
          </div>
        </div>
      }
    </div>
  }
</div>
