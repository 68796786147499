import { NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconAnchor } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { RouterLink } from '@angular/router';
import { SvgIconDirective } from '@bs/common/directives/svg-icon.directive';
import { UnsubscribeService } from '@bs/common/services/unsubscribe.service';
import { MaggieMenu } from '@bs/models/cms/enumMenu';
import { IMenuItem, IMenuStatus } from '@bs/models/cms/menu';
import { EnvironmentConfig } from '@bs/models/common/environment-config';
import { IMe } from '@bs/models/me/me';
import { MenuResolver } from '@bs/services/resolvers/core/menu.resolver';
import { AuthService } from '@bs/services/services/core/auth.service';
import { CmsService } from '@bs/services/services/core/cms.service';
import { DropdownLanguageComponent } from '@homer/common/components/dropdown-language/dropdown-language.component';
import { MenuComponent } from '@homer/common/components/menu/menu.component';
import { MobileChatBtnComponent } from '@homer/common/components/mobile-chat-btn/mobile-chat-btn.component';
import { SmarticoSidebarComponent } from '@homer/smartico/components/smartico-sidebar/smartico-sidebar.component';
import { TranslateModule } from '@ngx-translate/core';
import { EMPTY, map, Subscription, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'sidebar-menu-maggie',
  templateUrl: 'sidebar-menu.component.html',
  standalone: true,
  imports: [NgTemplateOutlet, TranslateModule, MatIconAnchor, RouterLink, MatDivider, MobileChatBtnComponent, SvgIconDirective, MenuComponent, SmarticoSidebarComponent, DropdownLanguageComponent]
})

export class SidebarMenuComponent extends UnsubscribeService {

  menuStatus: IMenuStatus;

  @Input()
  loadMenu: 'main' | 'side' | 'section';

  @Input()
  isMobile: boolean;

  mainMenu: IMenuItem;
  serviceMenu: IMenuItem;
  accountMenu: IMenuItem;
  subs = new Subscription();
  me: IMe;
  hasChat = this.config.liveChats?.tawkUrl || this.config.liveChats?.liveChatInc;
  lineChat = this.config.liveChats?.lineChat;

  constructor(cmsService: CmsService, authService: AuthService, menuService: MenuResolver,
              private config: EnvironmentConfig) {

    super();
    menuService.menuStatus$.subscribe({
      next: status => this.menuStatus = status
    });

    authService.accountLogged$.pipe(
      takeUntil(this.unsubscribe$),
      switchMap(account => {
        if (account) {
          return cmsService.getMenu(MaggieMenu.MeNavigationLogged).pipe(map(menu => ({ menu, account })));
        }
        return EMPTY;
      })).subscribe({
      next: ({ account, menu }) => {
        this.me = account;
        this.accountMenu = menu;
        //cdr.markForCheck();
      },
      error: err => console.error(err)
    });

    cmsService.getMenu(MaggieMenu.Main).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: menu => {
        this.mainMenu = menu;
        //cdr.markForCheck();
      }
    });

    cmsService.getMenu(MaggieMenu.Services).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: menu => {
        this.serviceMenu = menu;
        //cdr.markForCheck();
      }
    });
  }
}
