import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { NgClass } from '@angular/common';
import { Component, forwardRef, OnInit } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatError, MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatSelect } from '@angular/material/select';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatTooltip } from '@angular/material/tooltip';
import { SvgIconDirective } from '@bs/common/directives/svg-icon.directive';
import { BaseField } from '@bs/forms/base/base-field';
import { RestrictInputDirective } from '@bs/forms/directives/restrict-input.directive';
import { TranslateModule } from '@ngx-translate/core';
import { BirthPlaceComponent } from '../birth-place/birth-place.component';
import { CausalGroupsComponent } from '../causal-groups/causal-groups.component';
import { DateRangeComponent } from '../date-range/date-range.component';
import { DateSelectorComponent } from '../date-selector/date-selector.component';
import { FieldErrorComponent } from '../field-error/field-error.component';
import { FileUrlComponent } from '../file-url/file-url.component';
import { GeoInfosComponent } from '../geo-infos/geo-infos.component';
import { MobilePrefixComponent } from '../mobile-prefix/mobile-prefix.component';

/**
 * component act as a parent cva for all the cva, so we can switch the template field by input type passed
 */
@Component({
  selector: 'field[input], field[formControlName][type], field[ngModel]',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [NgClass, FormsModule, MatFormField, MatLabel, MatInput, MatDatepickerInput, MatDatepickerToggle, MatSuffix, MatDatepicker, MatCheckbox, DateRangeComponent, MobilePrefixComponent, MatIconButton, CausalGroupsComponent, MatRadioGroup, MatRadioButton, MatSlideToggle, CdkTextareaAutosize, MatSelect, MatOption, DateSelectorComponent, GeoInfosComponent, BirthPlaceComponent, FileUrlComponent, MatTooltip, MatError, FieldErrorComponent, TranslateModule, SvgIconDirective, RestrictInputDirective]
})
export class FieldComponent extends BaseField implements OnInit {
  /**
   * the years range of birthDate
   */
  yearRange: string;
  /**
   * the birthDate field has range
   */
  hasRange: boolean;
  /**
   * toggles the type and icon of password field
   */
  toggle: boolean;


  /**
   * execute the constructor of the base class, and sets years range on birthDate field
   */
  ngOnInit(): void {
    super._ngOnInit();

    if (this.input.name === 'birthDate') {
      this.hasRange = true;
      const year = new Date().getFullYear();
      this.yearRange = `${year - 99}:${year}`;
    }

  }

}
