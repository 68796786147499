<ng-template #emptyTpl let-tab>
  <div class="alert alert-warning m-5" role="alert">
    <strong>{{ 'empty' | translate }}!</strong> {{ 'pleaseAdd' | translate }} {{ tab.value }} {{ 'address' | translate }}
  </div>
</ng-template>

<ng-template #staticTpl let-geoInfo let-i="index" let-tab="tab">
  <div class="row m-0 p-2">
    <div class="col-12">
      <mat-form-field>
        <mat-label>{{ 'country' | translate }}</mat-label>
        <input [value]="geoInfo.country.name" disabled matInput/>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'region' | translate }}</mat-label>
        <input [value]="geoInfo.region.name" disabled matInput/>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'province' | translate }}</mat-label>
        <input [value]="geoInfo.province.name" disabled matInput/>
      </mat-form-field>
    </div>

    <div class="col-12">
      <mat-form-field>
        <mat-label>{{ 'city' | translate }}</mat-label>
        <input [value]="geoInfo.city.name" disabled matInput/>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'address' | translate }}</mat-label>
        <input [value]="geoInfo.address" disabled matInput/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'zipCode' | translate }}</mat-label>
        <input [value]="geoInfo.zipCode" disabled matInput/>
      </mat-form-field>
    </div>

    <div class="col-12 text-right">
      @if ((permission.CanEditPersonalDetails | hasPermission) && options.isRemovable) {
        <button (click)="removeGeoInfo(geoInfo, i)" [disabled]="!geoInfos?.valid && geoInfos.at(i).valid" class="mb-1" color="warn" mat-raised-button>
          <span>{{ 'remove' | translate }}</span>
        </button>
      }
    </div>
  </div>


</ng-template>

<ng-template #fillTpl let-geoInfo let-i="index" let-tab="tab">
  <div class="row m-0 py-2">
    <ng-container [formGroup]="geoInfo">
      <div class="col-12">
        <mat-form-field>
          <mat-label>{{ 'country' | translate }}</mat-label>
          <mat-select (ngModelChange)="setRegions($event, i)" formControlName="country" (openedChange)="dropdownOpened($event, countryInput)">
            <mat-form-field appearance="fill" class="dropdown-search-filter">
              <input type="text" matInput [(ngModel)]="searchFilter.country" #countryInput autocomplete="off" [ngModelOptions]="{standalone:true}"/>
              @if (searchFilter.country) {
                <button matSuffix mat-icon-button (click)="searchFilter.country=''"></button>
              }
            </mat-form-field>
            @for (country of (countries | filter: searchFn: searchFilter.country); track country) {
              <mat-option [value]="country">{{ country.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        @if (regions[i]) {
          <mat-form-field>
            <mat-label>{{ 'region' | translate }}</mat-label>
            @switch (!!regions[i].length) {
              @default {
                <input formControlName="region" matInput/>
              }
              @case (true) {
                <mat-select (ngModelChange)="setProvinces($event, i)" formControlName="region" (openedChange)="dropdownOpened($event, regionInput)">
                  <mat-form-field appearance="fill" class="dropdown-search-filter">
                    <input type="text" matInput [(ngModel)]="searchFilter.region" #regionInput autocomplete="off" [ngModelOptions]="{standalone:true}"/>
                    @if (searchFilter.region) {
                      <button matSuffix mat-icon-button (click)="searchFilter.region=''"></button>
                    }
                  </mat-form-field>
                  @for (region of (regions[i] | filter: searchFn: searchFilter.region); track region) {
                    <mat-option [value]="region">{{ region.name }}</mat-option>
                  }
                </mat-select>
              }
            }
          </mat-form-field>
        }

        @if (provinces[i]) {
          <mat-form-field>
            <mat-label>{{ 'province' | translate }}</mat-label>
            @switch (!!provinces[i].length) {
              @default {
                <input formControlName="province" matInput/>
              }
              @case (true) {
                <mat-select (ngModelChange)="setCities($event,i)" formControlName="province" (openedChange)="dropdownOpened($event, provinceInput)">
                  <mat-form-field appearance="fill" class="dropdown-search-filter">
                    <input type="text" matInput [(ngModel)]="searchFilter.province" #provinceInput autocomplete="off" [ngModelOptions]="{standalone:true}"/>
                    @if (searchFilter.province) {
                      <button matSuffix mat-icon-button (click)="searchFilter.province=''"></button>
                    }
                  </mat-form-field>
                  @for (province of (provinces[i] | filter: searchFn: searchFilter.province); track province) {
                    <mat-option [value]="province">{{ province.name }}</mat-option>
                  }
                </mat-select>
              }
            }
            <mat-error [control]="geoInfos.at(i).get('province')"></mat-error>
          </mat-form-field>
        }

        @if (cities[i]) {
          <mat-form-field>
            <mat-label>{{ 'city' | translate }}</mat-label>
            @switch (!!cities[i].length) {
              @default {
                <input formControlName="city" matInput/>
              }
              @case (true) {
                <mat-select formControlName="city" (openedChange)="dropdownOpened($event, cityInput)">
                  <mat-form-field appearance="fill" class="dropdown-search-filter">
                    <input type="text" matInput [(ngModel)]="searchFilter.city" #cityInput autocomplete="off" [ngModelOptions]="{standalone:true}"/>
                    @if (searchFilter.city) {
                      <button matSuffix mat-icon-button (click)="searchFilter.city=''"></button>
                    }
                  </mat-form-field>
                  @for (city of (cities[i] | filter: searchFn: searchFilter.city); track city) {
                    <mat-option [value]="city">{{ city.name }}</mat-option>
                  }
                </mat-select>
              }
            }
            <mat-error [control]="geoInfos.at(i).get('city')"></mat-error>
          </mat-form-field>
        }
      </div>
      <div class="col-12">
        <mat-form-field>
          <mat-label>{{ 'address' | translate }}</mat-label>
          <input type="text" formControlName="address" matInput/>
          <mat-error [control]="geoInfos.at(i).get('address')"></mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'zipCode' | translate }}</mat-label>
          <input type="text" formControlName="zipCode" matInput/>
          <mat-error [control]="geoInfos.at(i).get('zipCode')"></mat-error>
        </mat-form-field>
      </div>
    </ng-container>
    <ng-content></ng-content>
  </div>
</ng-template>

<ng-template #geoInfosTpl let-tab>
  @for (geoInfo of geoInfos.controls; track geoInfo; let i = $index) {
    @if (geoInfo.get('typeId').value === tab.key) {
      <ng-template [ngTemplateOutletContext]="{$implicit: geoInfos.at(i).value.id ? geoInfos.at(i).value:geoInfo, index:i, tab:tab}" [ngTemplateOutlet]="geoInfos.at(i).value.id ? staticTpl: fillTpl"></ng-template>
    }
  }
</ng-template>

<mat-tab-group [class.hide-tabs]="typeKeys.length === 1" animationDuration="0" class="geo-infos-container">
  @for (tab of typeKeys; track tab) {
    <mat-tab [disabled]="!geoInfos?.valid" [label]="tab.value">
      <ng-container [ngTemplateOutletContext]="{$implicit:tab}" [ngTemplateOutlet]="geoInfos?.controls.length ? geoInfosTpl : emptyTpl"></ng-container>
      @if (isVisible(tab)) {
        <button (click)="addGeoInfo(tab.key)" [disabled]="!geoInfos?.valid || isDisabled" class="my-2 geo-info-add" color="accent" mat-raised-button>
          <div class="d-flex align-items-center">
            <svg icon="plus-circle" lib="global" class="pe-1" [size]="{w:18, h:18}"></svg>
            <span>{{ 'add' | translate }}</span>
          </div>
        </button>
      }
    </mat-tab>
  }
</mat-tab-group>
