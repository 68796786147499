<div class="dialog">
  <mat-card class="new-card flat-card">

    <mat-card-title class="box-header">
      <div class="d-flex align-items-center">
        <svg icon="cog" lib="global" [size]="{w:9, h:9}" class="me-2"></svg>
        <span [translate]="'settings'">Settings</span>
      </div>
      <a (click)="close()" [routerLink]="[]" [fragment]="null" class="close-icon me-2">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </a>
    </mat-card-title>

    <div class="dialog-wrapper">
      <div class="scrollbar">

        <div class="dialog-body settings-dialog-container">
          <div class="row g-3">
            <div class="col-12">
              <dropdown-language></dropdown-language>
            </div>
            <div class="col-12">
              <dropdown-timezones></dropdown-timezones>
            </div>
            @if (allowThemeSwitch) {
              <div class="col-12">
                <theme-switcher></theme-switcher>
              </div>
            }
          </div>
        </div>
      </div>

    </div>

  </mat-card>
</div>
