<div class="mobile-prefix-container">
  <div class="prefix-field">
    @if (model?.prefix) {
      <mat-form-field appearance="outline">
        <div class="d-flex align-items-center">
          <svg [icon]="(model.prefix.code.length > 2 ? (model.prefix.code | slice:3:5 ): model.prefix?.code ) | lowercase" lib="flags" class="me-1" [size]="{w:20, h:14}"></svg>
          <mat-select (valueChange)="update(model)" [disableOptionCentering]="true" [(ngModel)]="model.prefix" [disabled]="isDisabled" id="mobile-prefix">
            @for (c of countries; track c) {
              <mat-option [value]="c">
                <!--          {{ c.name }}-->
                ({{ c.prefix }})
              </mat-option>
            }
          </mat-select>
        </div>
      </mat-form-field>
    }
  </div>

  @if (model) {
    <mat-form-field appearance="outline">
      <input (blur)="onBlur($event)" [readonly]="readonly" (ngModelChange)="update({phone:$event, prefix:model.prefix})" [(ngModel)]="model.phone"
             [placeholder]="placeholder" [restrict]="['integer']" matInput id="mobile-phone" type="tel">
    </mat-form-field>
  }

</div>
<ng-content select="mat-error"></ng-content>
