import { NgTemplateOutlet } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { FilterPipe } from '@bs/common/pipes/filter.pipe';
import { BaseBirthPlace } from '@bs/forms/base/base-birth-place';
import { ICountry } from '@bs/models/common/country';
import { MaterialComponentsModule } from '@homer/mat-components';
import { TranslateModule } from '@ngx-translate/core';

/**
 * The component contains us birthplace fields
 *
 * @description input field for country, region, province, city
 */
@Component({
  selector: 'birth-place',
  templateUrl: './birth-place.component.html',
  styleUrls: ['./birth-place.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BirthPlaceComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, MatSelect, MaterialComponentsModule, MatIconButton, MatSuffix, MatOption, NgTemplateOutlet, TranslateModule, FilterPipe]
})
export class BirthPlaceComponent extends BaseBirthPlace {
  /**
   * the label for the field
   */
  @Input()
  label = 'birthplace';

  /**
   * function focuses search input when dropdown opens
   * @param opened
   * @param input
   */
  focusInput(opened: boolean, input: HTMLInputElement): void {
    if (opened) {
      input.focus();
    }
  }

  /**
   * function on key press focuses first coming element option
   * @param select
   */
  focusOptions(select: MatSelect) {
    select.options.first.select();
  }

  searchFn = (items: ICountry, search: string) => {
    if (!search) {
      return items;
    }
    return items.name.toLowerCase().includes(search);
  };
}
