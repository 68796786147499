<div class="row flex-column flex-md-row">

  <mat-form-field class="form-group col">
    <mat-label>{{'causalGroup' | translate}}</mat-label>
    <mat-select (blur)="onBlur($event)" (ngModelChange)="causalGroupChange($event?.id)" [placeholder]="'all' | translate" [(ngModel)]="causalGroupId" [disabled]="isDisabled" class="form-control" id="causalGroupId" name="causalGroupId">
      <mat-option [value]="null">{{'all' | translate}}</mat-option>
      @for (option of causalGroupsValues; track option) {
        <mat-option [value]="option">{{option.name}}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field class="form-group col mt-3 mt-md-0">
    <mat-label>{{'causal' | translate}}</mat-label>
    <mat-select (blur)="onBlur($event)" (ngModelChange)="update(causalGroupId.id, $event?.id)" [placeholder]="'all' | translate" [(ngModel)]="causalId" [disabled]="isDisabled || causalGroupId === null" class="form-control" id="causalId" name="causalId">
      <mat-option [value]="null">{{'all' | translate}}</mat-option>
      @for (option of causalIdsValues; track option) {
        <mat-option [value]="option">{{option.name}}</mat-option>
      }
    </mat-select>
  </mat-form-field>

</div>
