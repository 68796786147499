@if (html.length >= 1) {
  <mat-card class="pre-footer p-4 justify-content-center align-items-center m-3 m-lg-4">
    <mat-card-content class="content" [class.preview]="fullView">
      <p [innerHTML]="html"></p>
    </mat-card-content>
  </mat-card>
  <div class="row m-2">
    <div class="col-12 d-flex justify-content-center align-items-center">
      <button mat-raised-button (click)="changeView()">
        {{ fullView ? 'viewLess' : 'viewMore' }}
      </button>
    </div>
  </div>
}
