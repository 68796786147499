import { Component, HostBinding, Inject } from '@angular/core';
import { MatCard, MatCardTitle } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { SvgIconDirective } from '@bs/common/directives/svg-icon.directive';
import { TranslateModule } from '@ngx-translate/core';
import { LoginComponent } from '../../components/login/login.component';

interface MyData {
  username: any;
}

@Component({
  selector: 'login-dialog',
  templateUrl: 'login-dialog.component.html',
  styleUrls: ['login-dialog.component.scss'],
  standalone: true,
  imports: [MatCard, MatCardTitle, RouterLink, LoginComponent, TranslateModule, SvgIconDirective]
})
export class LoginDialog {
  @HostBinding('class')
  className = '';

  constructor(public dialogRef: MatDialogRef<LoginDialog>, @Inject(MAT_DIALOG_DATA) public data: MyData) {
  }

}
