<div class="dialog">
  <mat-card class="new-card flat-card">
    <mat-card-title class="box-header">
      <span class="d-flex aligh-items-center">
        <svg icon="log-in" lib="global" [size]="{w:14, h:14}" class="me-2"></svg>
        <span>{{ 'login-dialog-title' | translate }}</span>
      </span>
      <a (click)="dialogRef.close()" [routerLink]="[]" [fragment]="null" class="me-2 close-icon" type="button">
        <svg icon="close" lib="global" [size]="{w:9, h:9}"></svg>
      </a>
    </mat-card-title>
    <div class="dialog-wrapper">
      <div class="login-wrapper dialog-body row position-relative">
        <login (outcome)="dialogRef.close($event)" [data]="data" class="col-12"></login>
      </div>
    </div>
  </mat-card>
</div>
