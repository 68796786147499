<div class="buttonbar d-flex justify-content-between align-items-center px-3 py-2">
  <ng-content select="mat-label"></ng-content>
  @if (!file && !model) {
    <button (click)="fileUpload.click()" type="button" color="primary" mat-flat-button>{{ 'choose' }}
    </button>
  }
  @if (file) {
    <button (click)="upload()" type="button" mat-flat-button color="accent">{{ 'upload' | translate }}</button>
  }
</div>
<div class="file-content">
  @if (file) {
    <div class="d-flex align-items-center">
      <span class="me-3"></span>
      <span class="file-text">
        @if (file) {
          <span class="file-name">{{ file.name }}</span>
        }
        @if (fileErrorMessage) {
          <span class="file-error">{{ fileErrorMessage }}</span>
        }
      </span>
      @if (file) {
        <button (click)="onRemoveFile()" type="button" mat-button>&times;</button>
      }
    </div>
  }
  @if (model) {
    <mat-form-field class="d-flex">
      <input type="text" [(ngModel)]="model" matInput readonly>
    </mat-form-field>
  }
</div>
<input type="file" class="file-input" [accept]="options?.accept" (change)="onFileSelected($event)"
       (click)="reset($event)" #fileUpload>
