<ng-template #errorTpl>
  @if ((errorMessage|async) !== null) {
    <div class="alert alert-{{className}}">{{(errorMessage|async)}}</div>
  }
</ng-template>

<ng-template #warningTpl>
  <div class="alert alert-warning d-flex justify-content-between mt-2">
    @if (!warning?.value) {
      <span>{{warning.message | translate}}</span>
    }
    @if (warning?.value) {
      <span>{{warning.message | translate:{value: warning.value} }}</span>
    }
    @if (warning.dismissible) {
      <button mat-icon-button class="p-0" (click)="warning = null">&times;</button>
    }
  </div>
</ng-template>

<ng-template [ngTemplateOutlet]="warning ? warningTpl:errorTpl"></ng-template>
