import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Params } from '@angular/router';

interface Month {
  name: string;
  value: number;
}

export interface IDateSelector {
  birthYear: number;
  birthMonth: number;
  birthDay: number;
}

/**
 *  \    / /\  |_) |\ |  |  |\ | /__
 *   \/\/ /--\ | \ | \| _|_ | \| \_|
 *
 *  DUE TO THE IMPACT THIS FILE HAS ON THE PLATFORM DO NOT CHANGE ANYTHING AND REFER TO YOUR TEAM LEADER
 * */
@Component({ template: `` })
export class BaseDateSelector {

  dates: Array<number>;
  months: Array<Month>;
  years: Array<number>;
  allMonths!: Array<Month>;

  year!: number | undefined;
  month!: Month | undefined;
  date!: number | undefined;

  modelDate!: Date;

  isDisabled!: boolean;

  touched: { date?: boolean, month?: boolean, year: boolean };

  @Input()
  min!: Date;

  @Input()
  max!: Date;

  @Input()
  options: Params | undefined = { isObject: false };

  @Output()
  blur = new EventEmitter<FocusEvent>();

  constructor() {
    this.dates = new Array<number>();
    this.years = new Array<number>();
    this.months = new Array<Month>();
    this.touched = { date: false, month: false, year: false };
  }

  onInit() {
    this.min = this.min ? new Date(this.min) : this.defaults(true);
    this.max = this.max ? new Date(this.max) : this.defaults(false);
    this.dates = this.updateDates();
    this.months = this.updateMonths();
    this.years = this.updateYears();
  }

  modelChanged(event: number | Month | undefined, select: string) {
    switch (select) {
      case 'year':
        if (typeof event === 'number') {
          this.months = this.updateMonths(event);
        }
        break;
      case 'month':
        if (typeof event === 'object') {
          this.dates = this.updateDates(event);
        }
        break;
      case 'date':
        this.updateModel();
        break;
    }
  }

  onBlur(event: FocusEvent, which: 'date' | 'month' | 'year') {
    this.touched[which] = true;
    this.blur.emit(event);
    if (this.touched.date && this.touched.month && this.touched.year) {
      this._onTouched();
    }
  }

  protected updateDates(month?: Month): Array<number> {
    let minDate, maxDate;

    if (month) {
      this.month = month;
    }

    const dates = new Array<number>();

    if (this.year && month && (this.min.getFullYear() === this.year && this.min.getMonth() === month.value - 1)) {
      minDate = this.min.getDate();
    } else {
      minDate = 1;
    }

    if (this.year && month && (this.max.getFullYear() === this.year && this.max.getMonth() === month.value - 1)) {
      maxDate = this.max.getDate();
    } else if (this.year && month) {
      maxDate = this.getDaysInMonth(this.year, month.value - 1);
    } else {
      maxDate = 31;
    }

    for (let i = minDate; i <= maxDate; i++) {
      dates.push(i);
    }

    if (this.date && (this.date > maxDate || this.date < minDate)) {
      this.date = 1;
    }

    this.updateModel();

    return dates;
  }

  protected updateMonths(year?: number): Array<Month> {

    let months = new Array<Month>();

    if (year === this.min.getFullYear() || year === this.max.getFullYear()) {

      const minMonth = year && (this.min.getFullYear() === year) ? this.min.getMonth() : 0;
      const maxMonth = year && (this.max.getFullYear() === year) ? this.max.getMonth() : 11;

      for (let j = minMonth; j <= maxMonth; j++) {
        months.push(this.allMonths[j]);
      }

      const sel = this.modelDate.getMonth() ? months.find(m => m.value === this.modelDate.getMonth() + 1) : months[0];
      this.dates = this.updateDates(sel);

    } else {
      this.dates = this.updateDates(this.month);
      months = this.allMonths;
    }

    return months;
  }

  protected getMonthsName(locale: string, size: 'long' | 'numeric' | '2-digit' | 'short' | 'narrow' | undefined) {
    const format = new Intl.DateTimeFormat(locale, { month: size });
    const months = [];
    for (let month = 0; month < 12; month++) {
      const testDate = new Date(2000, month, 1, 0, 0, 0);
      months.push({ name: format.format(testDate), value: month + 1 });
    }
    return months;
  }

  protected _onChange(_model: string | IDateSelector) {
  }

  protected _onTouched() {
  }

  private defaults(isMin: boolean): Date {
    const m = new Date();
    const y = isMin ? m.getFullYear() - 100 : m.getFullYear() + 50;
    m.setFullYear(y);
    return m;
  }

  private isLeapYear(year: number): boolean {
    return ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0);
  }

  private getDaysInMonth(year: number, month: number): number {
    return [31, (this.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
  }

  private updateYears(): Array<number> {
    const years = new Array<number>();
    for (let i = this.min.getFullYear(); i <= this.max.getFullYear(); i++) {
      years.push(i);
    }
    return years;
  }

  private pad(n: number): string {
    return n < 10 ? '0' + n : `${n}`;
  }

  private updateModel() {
    if (this.year && this.month && this.date) {

      if (this.options?.isObject) {
        this._onChange({ birthYear: this.year, birthMonth: this.month.value, birthDay: this.date });
      } else {
        const ISODate = this.pad(this.year) + '-' + this.pad(this.month.value) + '-' + this.pad(this.date);
        this._onChange(new Date(ISODate).toISOString());
      }
    }
  }

}
