import { Webapps } from '@bs/models/cms/webapps';
import { EnvironmentConfig } from '@bs/models/common/environment-config';
import packageInfo from 'package.json';

export const environment: EnvironmentConfig = {
  production: true,
  api: {
    accounts: 'https://api-accounts-stg.betstarters.cloud',
    catalogs: 'https://api-catalogs-stg.betstarters.cloud',
    cms: 'https://api-cms-stg.betstarters.cloud/cms',
    games: 'https://api-games-stg.betstarters.cloud',
    identity: 'https://api-identity-stg.betstarters.cloud',
    promo: 'https://api-promo-stg.betstarters.cloud',
    reports: 'https://api-reports-stg.betstarters.cloud',
    sportsbook: 'https://api-sportsbook-stg.betstarters.cloud',
  },
  appVersion: packageInfo.version,
  bookmakerId: 4,
  webAppId: Webapps.maggie,
  defaultLangCode: 'en',
  externalScripts: [],
  features: {
    bottomSheet: false,
    passwordRecovery: {
      email: false,
      phone: true
    },
    registrationModes: 'phoneFast',
  },
  langs: [{
    code: 'en',
    name: 'English'
  }],
  layout: {
    footer: 'fullWidth',
  },
  theme: {
    defaultDark: false
  },
  project: 'betstarters.cloud',
  depositPage: 'me/balances/deposit',
  homePage: 'games/casino',
  timeZone: 'Europe/Malta'
};

