<ng-template #sidebarToggleBtnTpl>
  <button mat-flat-button class="sidebar-toggle icon-left mb-2 pe-3 pe-sm-4" (click)="openMenu('main')">
    <div [ngClass]="{open:menuStatus.main}" class="menu-button">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </button>
</ng-template>

<ng-template #desktopTpl>
  <div class="header-desktop">
    <div class="d-flex align-items-center h-100">
      <div class="d-flex justify-content-between align-items-center px-3 w-100">
        <span></span>
        <!--        <button mat-flat-button (click)="openMenu('section')" class="sidebar-toggle" [class.expanded]="menuStatus.section">-->
        <!--          <img src="assets/images/collapse-btn.svg" alt="sidebar collapse icon">-->
        <!--        </button>-->

        <!--        <button mat-flat-button class="sidebar-toggle-cp d-none" (click)="openMenu('section')" [class.expanded]="menuStatus.section">-->
        <!--          <div [ngClass]="{open:menuStatus.main}" class="menu-button">-->
        <!--            <span></span>-->
        <!--            <span></span>-->
        <!--            <span></span>-->
        <!--          </div>-->
        <!--        </button>-->

        @if (me) {
          <div class="d-flex align-items-center">
            @if (me.typeId === 0) {
              <ng-content select="smartico-bar"></ng-content>
            }
            <button mat-flat-button [matMenuTriggerFor]="userMenuDropdown" class="me-2">
              <img width="20px" src="assets/images/silver-medal.svg" alt="avatar">
              <span class="ms-2">{{ me.username }}</span>
              <svg icon="chevron-down" lib="global" [size]="{w:18, h:18}"></svg>
            </button>
            <mat-menu #userMenuDropdown="matMenu" class="user-menu">
              <menu [menu]="userMenu"></menu>
            </mat-menu>
            @if (me.typeId === 0) {
              <ng-content select="deep-link"></ng-content>
              <div class="user-balance d-flex align-items-center">
                <span>{{ totalBalance | moneyFormat:me.currencies[0] }}</span>
                <button [matMenuTriggerFor]="balance" (click)="reloadBalance()" class="p-0 me-3 ms-3" mat-button type="button">
                  <svg icon="chevron-down" lib="global" [size]="{w:18, h:18}"></svg>
                </button>
                <mat-menu #balance="matMenu" class="px-2 balance-dropdown mt-3">
                  <div class="balance-dropdown-content no-gutters align-items-center">
                    <div class="col d-flex flex-column p-2 pe-3">
                      <span>{{ 'real-balance' | translate }}</span>
                      <strong>{{ (me.wallets[0].balance.available) | moneyFormat: me.currencies[0] }}</strong>
                    </div>
                    <div class="col d-flex flex-column p-2 pe-3">
                      <span>{{ 'bonus-balance' | translate }}</span>
                      <strong>{{ totalBonuses | moneyFormat: me.currencies[0] }}</strong>
                    </div>
                  </div>
                  <!--                <div class="balance-dropdown-content mt-2 p-2 d-flex align-items-center justify-content-between">-->
                  <!--                  <div class="d-flex align-items-center">-->
                  <!--                    <img src="/assets/images/smartico-sidebar/cashback.png" style="height: 40px;">-->
                  <!--                    <div class="d-flex flex-column ml-2">-->
                  <!--                      <div class="d-flex align-items-center">-->
                  <!--                        <strong>{{'cash-back' | translate}}</strong>-->
                  <!--                        <strong class="cashback-amount ml-2 px-2">0%</strong>-->
                  <!--                      </div>-->
                  <!--                      <strong>0.00 €</strong>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                  <div class="d-flex align-items-center">-->
                  <!--                    <div class="time p-2 ml-2 d-flex align-items-center mr-1">-->
                  <!--                      <span>06:03:00</span>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                </div>-->
                  <!--                <div class="balance-dropdown-content mt-2 p-2 d-flex align-items-center">-->
                  <!--                    <div class="d-flex align-items-center bonus-open p-1">-->
                  <!--                      <strong>100%+150FS</strong>-->
                  <!--                    </div>-->
                  <!--                    <strong class="ellipsis mx-2">{{'1st-welcome-bonuse' | translate}}</strong>-->
                  <!--                    <button class="get-bonus-btn justify-self-end" mat-flat-button type="button" color="primary">{{'getBonus' | translate}}</button>-->
                  <!--                </div>-->
                  <a [href]="currentLang+'/me/balances/bonuses'" class="mt-4 bonus-hub-btn" mat-flat-button color="primary">{{ 'bonus' | translate }}</a>
                </mat-menu>
                <a mat-flat-button color="primary" [routerLink]="depositRoute" class="deposit-btn">{{ 'deposit' | translate }}</a>
              </div>
            }
            <dropdown-language class="ms-2 d-flex align-items-center"></dropdown-language>
          </div>
        }

        @if (!me) {
          <div class="buttons d-flex align-items-center">
            <a class="me-3 stroked-btn" [routerLink]="[]" fragment="login" mat-stroked-button>{{ 'login' | translate }}</a>
            <!--            <a mat-flat-button [routerLink]="[]" fragment="register" color="primary">{{'register' | translate}}</a>-->
            <a mat-flat-button [routerLink]="[]" fragment="register" id="btn-register-desktop" color="primary">{{ 'register' | translate }}</a>
            <dropdown-language></dropdown-language>
          </div>
        }
      </div>
    </div>
  </div>
</ng-template>

<ng-template #mobileTpl>
  <div class="header-mobile">
    <div class="d-flex align-items-center h-100 ">
      <div class="d-flex justify-content-between align-items-center px-sm-3 w-100">
        <div class="d-flex">
          <ng-container [ngTemplateOutlet]="sidebarToggleBtnTpl"></ng-container>
          <a routerLink="/" class="d-flex justify-content-center">
            <img src="assets/images/logo.svg" class="logo">
            <div class="logo-background d-none ms-2"></div>
          </a>
        </div>
        @if (me) {
          <div class="d-flex align-items-center">`
            <div class="user-balance d-flex align-items-center ">
              <span>{{ totalBalance | moneyFormat:me.currencies[0] }}</span>
              <button (click)="reloadBalance()" mat-button type="button">
                <svg icon="reload" lib="global" [size]="{w:18, h:18}"></svg>
              </button>
              <a class="me-btn" mat-flat-button color="primary" [routerLink]="depositRoute">+</a>
            </div>
            <button mat-flat-button (click)="openMenu('section')" class="d-flex align-items-center ms-2 ms-sm-3 me-2">
              <svg icon="account" lib="global" [size]="{w:14, h:14}"></svg>
            </button>
          </div>
        }
        @if (!me) {
          <div class="buttons d-flex align-items-center">
            <a class="me-3 stroked-btn" [routerLink]="[]" fragment="login" mat-stroked-button>{{ 'login' | translate }}</a>
            <a mat-flat-button [routerLink]="[]" fragment="register" id="btn-register-mobile" color="primary">{{ 'register' | translate }}</a>
            <!--            <a mat-flat-button [routerLink]="[currentLang,'register']" color="primary">{{'register' | translate}}</a>-->
          </div>
        }
      </div>
    </div>
  </div>
</ng-template>

<header class="header-container">
  <ng-container [ngTemplateOutlet]="isMobile ? mobileTpl : desktopTpl"></ng-container>
</header>
