import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EnvironmentConfig } from '@bs/models/common/environment-config';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'file-url[options]',
  templateUrl: './file-url.component.html',
  styleUrls: ['./file-url.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUrlComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [MatButton, MatFormField, MatInput, FormsModule, TranslateModule]
})

export class FileUrlComponent implements ControlValueAccessor, OnInit {

  isDisabled: boolean;
  fileErrorMessage: string;
  model: string;
  file: File;

  @Output()
  onBlur: EventEmitter<ProgressEvent> = new EventEmitter<ProgressEvent>();

  @Input()
  options: { accept: string, maxFileSize: number };

  constructor(private config: EnvironmentConfig, private translate: TranslateService, private http: HttpClient, private snackBar: MatSnackBar) {
  }

  registerOnChange(fn: () => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: string) {
    if (obj) {
      this.model = obj;
    }
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  propagateChange(_model: string) {
  }

  onTouched() {
  }

  onFileSelected($event: Event) {
    const inputElement = $event.target as HTMLInputElement;
    const files: FileList = <FileList>inputElement.files;
    this.file = files[0];

    if (this.file) {
      if (this.file.size <= this.options.maxFileSize) {
        if (this.options.accept.replace(/ /g, '').split(',').includes(this.file.type)) {
          const reader = new FileReader();
          reader.readAsDataURL(this.file);
          reader.onload = () => {
            this.fileErrorMessage = '';
            // this.model = reader.result as string;
            //this.propagateChange(this.model);
          };
          reader.onerror = () => {
            // error
            this.fileErrorMessage = 'load error';
          };
          reader.onloadend = ($event) => {
            this.onBlur.emit($event);
          };
        } else {
          this.fileErrorMessage = this.translate.instant('invalidFileType');
        }
      } else {
        this.fileErrorMessage = this.translate.instant('invalidFileSize');
      }
    }
  }

  onRemoveFile() {
    this.file = null;
    // this.propagateChange(this.model);
    // this.onBlur(file);
  }

  reset($event: MouseEvent) {
    const element = $event.target as HTMLInputElement;
    element.value = '';
  }

  ngOnInit() {
    if (!this.options.maxFileSize) {
      throw new Error('maxFileSize option required');
    }

    if (!this.options.accept) {
      throw new Error('accept option required');
    }
  }

  upload() {
    const formData: FormData = new FormData();
    formData.append('type', 'back');
    formData.append('file', this.file, this.file.name);

    this.http.post<string>(`${this.config.api.identity}/me/documents/attachment`, formData).subscribe({
      next: success => {
        // return string url to be set in the model
        this.model = success;
        this.propagateChange(success);
        this.onRemoveFile();
        this.snackBar.open(this.translate.instant('successImageLoaded'), this.translate.instant('close'), { panelClass: 'success', duration: 3500 });
      },

      error: error => {
        this.snackBar.open(this.translate.instant('errorImageLoaded'), this.translate.instant('close'), { panelClass: 'success', duration: 3500 });
      }
    });
  }
}
